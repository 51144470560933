import { render, staticRenderFns } from "./FavoriteGames.vue?vue&type=template&id=742e9752&scoped=true&"
import script from "./FavoriteGames.vue?vue&type=script&lang=js&"
export * from "./FavoriteGames.vue?vue&type=script&lang=js&"
import style0 from "./FavoriteGames.vue?vue&type=style&index=0&id=742e9752&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "742e9752",
  null
  
)

export default component.exports