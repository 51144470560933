<template>
  <div>
    <div class="timer-char" v-if="char !== 0">
      <div class="timer-char-slider" :style="{ transform: 'translateY(' + sliderTop + 'px)' }">
        <div v-for="(option, idx) in options" :key="idx" class="timer-char-slider-option jackpot-text" :class="{ active: idx === currentOption }">
          <span>{{ option }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JackpotIncrementValue',
  props: {
    char: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: [],
      currentOption: 0,
      sliderTop: 0,
      //digitHeight: 24, 
      width: window.innerWidth,
    };
  },
  computed: {
    digitHeight() {
      if (this.$route.path.includes('game')) {
        if (this.width > 500) {
          return 25;
          
        } else{
          return 20;
        }
      } else if (this.width > 500) {
        return 30;
      } else{
        return 20;
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeFrame);
    this.initializeSlider();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFrame);
  },
  watch: {
    char(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateSlider();
      }
    },
  },
  methods: {
    resizeFrame() {
        this.width = window.innerWidth;
        this.initializeSlider();
    },
    initializeSlider() {
      const number = parseInt(this.char);

      for (let i = 0; i <= 9; i++) {
        this.options.push(i);
      }

      this.currentOption = number;
      this.sliderTop = -number * this.digitHeight;
    },
    updateSlider() {
      const number = parseInt(this.char);
      this.currentOption = number;
      this.sliderTop = -number * this.digitHeight;

      if (this.currentOption === 10) {
        setTimeout(() => {
          this.sliderTop = 0; // reset top 
        }, 500); // delay
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.timer-char {
  position: relative;
}

.timer-char-slider {
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
}

.timer-char-slider-option {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.active {
  font-weight: bold;
  //font-size: 2rem;
}
</style>
