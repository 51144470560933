import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Game from "../views/Game/Game";
import SignIn from "../views/SignIn";
import store from "../store/store";
const redirectIfNotAuth = (to, from, next) => {
  const user = store.getters.getUserInfo.id;
  // console.log("user", user);
  if (user === null) {
    next("/login");
  } else {
    next();
  }
};
const redirectIfAuth = (to, from, next) => {
  const user = store.getters.getUserInfo.id;
  if (user !== null) {
    next("/");
  } else {
    next();
  }
};
const redirectIfNotBlocked = (to, from, next) => {
  const country = store.state.isBlockedProduct;
  const product = store.state.isBlockedProduct;
  if (!product || !country) {
    next("/");
  } else {
    next();
  }
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: redirectIfNotAuth,
  },
  {
    path: "/game/:id",
    name: "Game",
    component: Game,
    beforeEnter: redirectIfNotAuth,
  },
  {
    path: "/login",
    name: "SignIn",
    component: SignIn,
    beforeEnter: redirectIfAuth,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash };
    } else {
      return {
        x: 0,
        y: 0,
        meta: { scrollPosition: from.meta.scrollPosition || 0 },
      };
    }
  },
});
router.beforeEach(async (to, from, next) => {
  const jwt = localStorage.getItem("jwt");
  window.scrollTo(0, 0);
  if (to.path === "/logout") {
    store.dispatch("logOut");
    next("/login");
  } else {
    if (to.name === "Login") {
      next();
    } else {
      next();
    }
  }
});

export default router;
