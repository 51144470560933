<template>
  <div id="jackpot">
    <div id="jackpot-text">
      <template v-if="!rtl">
        <jackpot-increment-value v-for="(char, index) in jackpotValues" :key="index" :char="char" />
      </template>
      <div class="jackpot-text" v-else>
        {{ jackpot.win_amount }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import JackpotIncrementValue from './JackpotIncrementValue.vue';

export default {
  components: {
    JackpotIncrementValue,
  },
  props: {
    rtl: {
      type: Boolean,
    }
  },
  data() {
    return {
      currentNumber: 0,
      oldCurrentNumber: 0,
      // jackpotValues: [
      //   '0',
      //   '0',
      //   '0',
      //   '0',
      //   '0',
      //   '0',
      // ],
      interval: null,
      isFirstUpdate: true,
      stopIncrement: false,
      accumulatedFraction: 0,
    };
  },
  // computed: {
  //   jackpotValues() {
  //     const hours = this.formatSegment(this.date.getHours() % 12 === 0 ? 12 : this.date.getHours() % 12);
  //     const minutes = this.formatSegment(this.date.getMinutes());
  //     const seconds = this.formatSegment(this.date.getSeconds());
  //     return [hours, minutes, seconds].join('').split('');
  //   },
  // },
  computed: {
    ...mapGetters({
      jackpotIncrease: 'getJackpotIncrease',
      jackpotWin: 'getJackpotWin',
      jackpot: 'getJackpot',
      user: 'getUserInfo',
    }),
    jackpotValues() {
      const roundedNumber = Math.floor(this.currentNumber);
        const numberStr = String(roundedNumber).padStart(1, '0');
        return numberStr.split('');
    },
  },
  watch: {
        jackpotWin:{
        handler(newV) {
          if(newV && this.user.id === this.jackpotWin.user_id) {
            this.stopIncrement = true;
          }
        },
        immediate: true,
        deep: true
      },
    'jackpotIncrease.progress': {
      handler(newProgress, oldProgress) {
        //console.log('new', newProgress);
        //console.log('old', oldProgress);
        if (newProgress > oldProgress) {
          if (this.stopIncrement) {
            // this.updateCurrentNumber(oldProgress, this.jackpot_amount);
            this.currentNumber = this.jackpot_amount;
            return;
          }
          if (this.isFirstUpdate) {
            // this.updateCurrentNumber(this.oldCurrentNumber, newProgress);
            this.currentNumber = newProgress;
            this.isFirstUpdate = false;
          } else{
            // this.updateCurrentNumber(oldProgress, newProgress);
            this.currentNumber = newProgress;
          }
        } 
      },
      immediate: true,
    },
  },
  methods: {
    updateCurrentNumber(oldProgress, newProgress) {
      const totalSteps = newProgress - oldProgress;
    let integerSteps = Math.floor(totalSteps); // Целая часть
    let fractionalSteps = totalSteps % 1; // Дробная часть

    const updateStep = () => {
      if (integerSteps > 0) {
        this.currentNumber += 1;
        integerSteps -= 1;
      } else if (fractionalSteps > 0) {
        const fractionalIncrement = Math.min(fractionalSteps, 1);
        console.log('fractionalSteps', fractionalSteps);
        this.currentNumber += fractionalIncrement;
        fractionalSteps -= fractionalIncrement;
      } else {
        return;
      }
      setTimeout(updateStep, 1000); // Обновляем каждую секунду
    };
  updateStep();
  this.stopIncrement = false;
},
    // updateCurrentNumber(oldProgress, newProgress) {
    //   const steps = newProgress - oldProgress;
    //   let step = 1;
    //   console.log('steps', steps);
    //   const updateStep = () => {
    //     if (step <= steps) {
    //       this.currentNumber += 1;
    //       step += 1;
    //       setTimeout(updateStep, 1000); 
    //     }
    //   };
    //   updateStep();
    //   this.stopIncrement = false;
    // },
  },
  mounted() {
    if (this.jackpotIncrease.agent_id && this.jackpotIncrease.progress > 0) {
      this.currentNumber = this.jackpotIncrease.progress;
      this.oldCurrentNumber = this.jackpotIncrease.progress;
    } else if (this.jackpot && this.jackpot.bets_progress > 0) {
      this.currentNumber = this.jackpot.bets_progress;
      this.oldCurrentNumber = this.jackpot.bets_progress;
      // this.currentNumber = this.updateCurrentNumber(1000, this.jackpot.bets_progress);
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
#jackpot {
    height: 30px; 
    overflow: hidden; 
    position: relative; 
    display: flex;
    align-items: center;
    flex-direction: column;
    ._compat &{
      height: 25px;
      @media (max-width: 500px) {
        height: 20px;
      }
    }
      @media (max-width: 500px) {
        height: 20px;
      }
}

#jackpot-text {
  display: flex;
  span{
    font-weight: bold;
    font-size: 2rem;
  }
}

/* Стили для перехода между цифрами */
.jackpot-char {
  position: relative;
  font-size: 2rem;
  margin: 0 5px;
}

.jackpot-char-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.jackpot-char-slider-option {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: top 0.5s ease-in-out;
}

.active {
  font-weight: bold;
  font-size: 2rem;
}
</style>
