import * as Fingerprint2 from 'fingerprintjs2'
const fingerprint = {
  get : () => new Promise(resolve => {
    new Fingerprint2.get((components) => {
      resolve({components: components});
    })
  })
};


export default fingerprint;