<template>
    <div>
        
      <v-dialog class="dialog-jackpot" persistent 
      no-click-animation
      v-model="dialog" content-class="jackpot-popup"
        max-width="625">
        <v-card flat class="jackpot">
          <!-- <v-card-title class="jackpot__header">
            {{ $t('jackpotsDepositeError') }}
          </v-card-title> -->
          <div class="jackpot__row">
            <div class="jackpot__column">
                <div class="jackpot__image">
                  <img src="@/assets/images/jackpot-lose.jpeg" alt="jackpot-lose">
                </div>
            </div>
            <div class="jackpot__column">
                <div class="jackpot__content mt-2">
                  <v-card-text class="jackpot__text">
                    <span> 
                        {{ $t('jackLoseText')}}: 
                    </span>
                      <p>
                          {{ `${$t('winner')}: ${userName}` }}
                      </p>
                    <!-- <span> 
                        {{ $t('jackWinSubText')}} 
                    </span> -->
                  </v-card-text>
                  <v-card-actions class="jackpot__buttons">
                    <button @click="okayButton"
                     class="jackpot__continue button button_small button_red">
                        {{ $t('continue') }}
                    </button>
                </v-card-actions>
                </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import {mapGetters} from 'vuex'
  export default {
    name: 'JackpotLoseDialog',
    components:{
    }, 
    data() {
      return {
        dialog: false,
        userName: null
      }
    },
    methods: {
      async okayButton() {
        this.dialog = false;
      },
    },
    watch: {
      jackpotWin:{
        async handler(newV) {
         if (newV.user_id && newV.user_id !== this.user.id) {
            this.dialog = true;
            this.userName = newV.username
            this.$store.commit('JACKPOT_NULL')
          }
        },
        immediate: true,
        deep: true
      }
    },
    computed: {
      ...mapGetters({
        jackpot: 'getJackpot',
        jackpotWin: 'getJackpotWin',
        user: 'getUserInfo',
      })
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .jackpot {
    position: relative;
            background-color: var(--gray)!important;
    animation: gradientAnimation 5s ease infinite;
      border: 2px solid rgba(252, 81, 132, 0.459)!important;
    max-width: 400px;
    overflow: hidden;
    border-radius: 16px!important;
    @media (max-height: 500px) and (orientation: landscape) {
      max-width: 625px;
    }
  
    color: var(--white) !important;
  
    &__row {
      @media (max-height: 500px) and (orientation: landscape) {
        display: flex;
      }
    }
    &__column {
      @media (max-height: 500px) and (orientation: landscape) {
        flex: 0 1 50%;
        &:last-child{
          
          display: flex;
          justify-content:center;align-items: center;
        }
      }
    }
    &__image {
        max-width: 100%;
        height: 275px;
    
        @media (max-height: 500px) and (orientation: landscape) {
          display: flex;
        }
    
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
  
    &__header {
      background-color: var(--dark-flamingo);
      justify-content: center;
      align-items: center;
      font-size: 14px !important;
      padding: 12px 16px !important;
      word-break: unset;
      //margin-bottom: 10px;
    }
  
    &__content {
      @media (max-height: 500px) and (orientation: landscape) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  
    &__text{
        display: flex;
        flex-direction: column;
      color: var(--white) !important;
        padding: 5px;
            p{
          font-weight: bold;
          color: var(--white);
          font-size: 20px;
          text-transform: uppercase;
          line-height: 1;
           //animation: glitch 5s linear infinite;
            //@keyframes glitch{
            //  2%,64%{
            //    transform: translate(2px,0) skew(0deg);
            //  }
            //  4%,60%{
            //    transform: translate(-2px,0) skew(0deg);
            //  }
            //  62%{
            //    transform: translate(0,0) skew(5deg); 
            //  }
            //}
              background: 50% 100% / 50% 50% no-repeat
              radial-gradient(ellipse at bottom, #fff, transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  animation: glow 2500ms linear infinite 2000ms;

  @keyframes glow {
    40% {
      text-shadow: 0 0 8px #fff;
    }
  }
        }
      span {
        font-weight: 700;
      }
  
      @media (max-height: 500px) and (orientation: landscape) {
        p {
          margin-bottom: 0;
        }
      }
    }
  
    &__buttons {
        display: flex;
        justify-content:center;align-items: center;

      @media (max-height: 500px) and (orientation: landscape) {
        padding-bottom: 3px !important;
        padding-top: 3px !important;
      }
  
      button {
        min-width: 120px !important;
      }
    }
  
    &__continue {}
  
    &__logout {
      margin-left: 20px !important;
    }
  
  
    &__link {
      color: var(--main-flamingo);
    }
  }</style>