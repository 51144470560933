<template>
    <div class="footer">
        <div class="footer__row footer__row_first">
            <div class="footer__side">
                <div class="footer__credit">
                    <div class="footer__credit-title">
                        {{ $t('balance') }}
                    </div>
                    <div class="footer__credit-value">
                        {{ `${user.balance} ${user.currency}` }}
                    </div>
                </div>
            </div>
            <div class="footer__side"></div>
        </div>
        
        <desktop-mini-footer
        @contact-modal="toOpenContactsDialog"
        @info-modal="toOpenInfoDialog"
        
        />
        <v-dialog v-model="dialog" max-width="500px" scrollable
        content-class="main-dialog"
        >
        <info-dialog 
        @close="toCloseDialog"
        v-if="isInfoDialog"
        >

        </info-dialog>
        <contacts-dialog 
        @close="toCloseDialog"
        v-if="isContactsDialog">

        </contacts-dialog>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoDialog from '@/components/Modal/InfoDialog';
import ContactsDialog from '@/components/Modal/ContactsDialog';
import DesktopMiniFooter from '@/components/DesktopMiniFooter';
    export default {
        name: "DesktopFooter",
        computed: {
            ...mapGetters({
                user: 'getUserInfo',
            })
        },
        components:{
            DesktopMiniFooter,
            ContactsDialog,
            InfoDialog
        },
        data() {
            return {
                isInfoDialog: false,
                isContactsDialog: false,
                dialog: false,
            }
        },
        methods:{
            toCloseDialog(){
                this.isContactsDialog = false;
                this.isInfoDialog = false;
                this.dialog = false;
                
            },
            toOpenInfoDialog(){
                this.isInfoDialog = true;
                this.isContactsDialog = false;
                this.dialog = true;
            },
            toOpenContactsDialog(){
                this.isInfoDialog = false;
                this.isContactsDialog = true;
                this.dialog = true;
            }
        },
    }
</script>

<style lang="scss" scoped>
.footer{
    width: 100%;
    height: 136px;
    background-color: var(--black);
    &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 0 25px;
        &_first {
            
            height: 101px;
            border-top: 2px solid #737375;
            background-image: linear-gradient(180deg, hsla(0, 0%, 100%, .25), #000);
            border-bottom: 1px solid #2b2d31;
        }
    
        &_second {
            height: 35px;
            flex: 1;
        }
    }
    
    &__side {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
    }
    
    &__credit {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    &__credit-title {
        color: var(--white);
    font-size: 16px;
    text-transform: uppercase;
    }
    
    &__credit-value {
        color: transparent;
        background: #fff linear-gradient(180deg, var(--white), rgba(147, 27, 163, 0.35));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 32px;
        text-transform: uppercase;
    }


		&__center {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            justify-content: center;
            position: absolute;
            z-index: 1;
            display: flex;
            align-items: center;
		}

		&__button {
		}

		

		&__username {	
            color: var(--gray);
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 700;
            margin-right: 90px;
        }

}

</style>