// currencies.js
import axios from "axios";
const state = {
  currencies: [],
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getCurrencies: (state) => state.currencies,
};

const actions = {
  async awaitGetCurrencies({ commit }) {
    await axios
      .get(`/api/informational/all_currencies`)
      .then((response) => {
        if (response.status === 200) {
          const currencies = response.data.currencies.map((currency) => ({
            text: currency,
            selected: false,
            selectToDeposit: false,
          }));
          commit("set", { type: "currencies", items: currencies });
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
