<template>
    <v-card>
        <v-card-title class="title-dialog">
            <span>{{ $t('info') }}</span>
        </v-card-title>
        <v-card-text>
            <div class="content-dialog">
                <h4 class="title-dialog _small mb-1">
                    <span>{{ $t('me') }}</span>
                </h4>
            <div class="content-dialog__wrapper">
                <div class="content-dialog__bonus-info">
                    <div class="content-dialog__title">{{ $t('balance') }}:</div>
                    <div class="content-dialog__value">{{ user.balance }} {{ user.currency }}</div>
                </div>
                <div class="content-dialog__bonus-info">
                    <div class="content-dialog__title">{{ $t('username') }}:</div>
                    <div class="content-dialog__value">{{ `${user.username} (${user.id})` }}</div>
                </div>
                </div>
                </div>
            <div class="content-dialog _agent">
                <h4 class="title-dialog _small mb-1">
                    <span>{{ $t('myAgent') }}</span>
                </h4>
            <div class="content-dialog__wrapper">
                <div class="content-dialog__bonus-info">
                    <div class="content-dialog__title">{{ $t('email') }}:</div>
                    <a v-if="user.agent && user.agent.email" :href="'mailto:' + user.agent.email" class="content-dialog__value">{{ user.agent.email }}</a>
                    <div v-else class="content-dialog__value">-</div>
                </div>
                <div class="content-dialog__bonus-info">
                    <div class="content-dialog__title">{{ $t('phone') }}:</div>
                    <a v-if="user.agent && user.agent.phone" :href="'tel:' + formatPhone(user.agent.phone)" class="content-dialog__value">{{ user.agent.phone }}</a>
                    <div v-else class="content-dialog__value">-</div>
                </div>
                </div>
                </div>
                </v-card-text>
                <v-card-actions>
                    <button  @click="$emit('close')" class="button dialog-button">
                        {{ $t('lobby') }}
                    </button>
                </v-card-actions>
    </v-card>
</template>

<script>
    import { mapGetters } from "vuex";
    export default {
        name: "InfoDialog",
        computed:{
            ...mapGetters({
                user: 'getUserInfo',
            }),
        },
        methods: {
            formatPhone(phone) {
                let cleanedPhone = phone.replace(/[^\d]/g, '');
                cleanedPhone = '+' + cleanedPhone;
                return cleanedPhone;
    }
        }
    }
</script>

<style lang="scss" scoped>
a{
    color: inherit;
    text-decoration: none;
    @media (any-hover: hover){
        &:hover{
            text-decoration: underline;
        }
    }
}
</style>