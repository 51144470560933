import Vue from "vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowRight,
  faMars,
  faVenus,
  faVenusMars,
  faCheckSquare,
  faExclamationTriangle,
  faInfoCircle,
  faPaw,
  faTrophy,
  faDice,
  faInfo,
  faPassport,
  faIdCard,
  faCrosshairs,
  faAddressCard,
  faCheckCircle,
  faMoneyCheckAlt,
  faUserSecret,
  faGifts,
  faGift,
  faHeadset,
  faUserCheck,
  faLongArrowAltLeft,
  faCreditCard,
  faRubleSign,
  faTimes,
  faPlus,
  faArrowDown,
  faArrowCircleDown,
  faList,
  faExpandArrowsAlt,
  faCompressArrowsAlt,
  faSortDown,
  faCheck,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faDice,
  faArrowRight,
  faMars,
  faVenus,
  faVenusMars,
  faCheckSquare,
  faExclamationTriangle,
  faInfoCircle,
  faPaw,
  faLock,
  faTrophy,
  faInfo,
  faGift,
  faIdCard,
  faPassport,
  faAddressCard,
  faCrosshairs,
  faCheckCircle,
  faMoneyCheckAlt,
  faUserSecret,
  faGifts,
  faHeadset,
  faUserCheck,
  faLongArrowAltLeft,
  faCreditCard,
  faRubleSign,
  faTimes,
  faPlus,
  faArrowDown,
  faArrowCircleDown,
  faList,
  faExpandArrowsAlt,
  faCompressArrowsAlt,
  faSortDown,
  faCheck
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
