<template>
    <div class="jackpot" :class="{'_compat' : $route.path.includes('/game')}">
        <div class="jackpot__wrapper">
            <div class="jackpot__content" :class="{'_rtl' : rtl }">
                <div class="jackpot__ctn">
                  <div class="jackpot__name value">
                    <div class="value__wrapper">
                      <div class="value__content">
                          <span>
                      {{
                        rtl ? $t('jackpot_drop') :
                      $t('jackpot_bank')
                  }}
                </span>
                </div>
                </div>
              </div>
                  <div class="jackpot__value" >
                                <template v-if="jackpot.id && jackpot.status !== 'finished'">
                                  <JackpotIncrement :jackpot="jackpot" :rtl="rtl" />
                                  
                                    <span class="jackpot-text ml-1">{{jackpot.currency ? jackpot.currency : ''}}</span>
                                </template>
                                <template v-else>
                                  <span class="jackpot-text" v-if="rtl">0</span>
                                  <div class="jackpot__name value" v-else>
                                    <div class="value__wrapper">
                                      <div class="value__content">
                                          <span>
                                      {{
                                        $t('played_out')
                                  }}
                                </span>
                                </div>
                                </div>
                              </div>
                              </template>
                          
                      </div>
                </div>
                <div class="jackpot__ctn jackpot__ctn_bottom" >
                    <div class="jackpot__name value">
                      <div class="value__wrapper">
                        <div class="value__content">
                            <span>
                        {{
                        $t('jackpot_drop')
                    }}
                  </span>
                  </div>
                  </div>
                </div>
                    <div class="jackpot__value" >
                                  <template v-if="jackpot.id && jackpot.status !== 'finished'">
                                    <JackpotIncrement :jackpot="jackpot" :rtl="true" />
                                      <span class="jackpot-text ml-1">{{jackpot.currency ? jackpot.currency : ''}}</span>
                                  </template>
                                  <template v-else>
                                    <div class="jackpot__name value">
                                      <div class="value__wrapper">
                                        <div class="value__content">
                                          <span>0</span>
                                  </div>
                                  </div>
                                </div>
                                </template>
                            
                        </div>
                    </div>
                </div>
            
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import JackpotIncrement from './JackpotIncrement.vue';
    export default {
      components:{
        JackpotIncrement
      },
        props:{
            rtl:{
                type: Boolean,
            }
        },
        name: 'JackpotItem',
        computed:{
            ...mapGetters({
                jackpot: 'getJackpot',
                user: 'getUserInfo'
            })
        },
    }
</script>

<style lang="scss" scoped>

.value {

    &__wrapper {
       //background-color: #141114;
  //background-image: linear-gradient(335deg, black 23px, transparent 23px),
  //  linear-gradient(155deg, black 23px, transparent 23px),
  //  linear-gradient(335deg, black 23px, transparent 23px),
  //  linear-gradient(155deg, black 23px, transparent 23px);
  //background-size: 58px 58px;
  //background-position: 0px 2px, 4px 35px, 29px 31px, 34px 6px;
  
    }

    &__content {
        //position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    //width: 50%;
    //height: 50%;
    background-image: radial-gradient(
      ellipse 50% 35% at 50% 50%,
      #6b1839,
      transparent
    );
    //transform: translate(-50%, -50%);
    letter-spacing: 2px;
    left: 50%;
    top: 50%;
//font-family: "Clip";
    text-transform: uppercase;
    //font-size: 6em;
    //color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
    animation: shine 4s forwards, flicker 3s infinite;
    
    }
}
  
  
  @keyframes blink {
    0%,
    22%,
    36%,
    75% {
      color: #ffe6ff;
      text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
        -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
        0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
    }
    28%,
    33% {
      color: #ff65bd;
      text-shadow: none;
    }
    82%,
    97% {
      color: #ff2483;
      text-shadow: none;
    }
  }
  
  .flicker {
    animation: shine 2s forwards, blink 3s 2s infinite;
  }
  
  .fast-flicker {
    animation: shine 2s forwards, blink 10s 1s infinite;
  }
  
  @keyframes shine {
    0% {
      color: #6b1839;
      text-shadow: none;
    }
    100% {
      color: #ffe6ff;
      text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
        -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
        0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
    }
  }
  
  @keyframes flicker {
    from {
      opacity: 1;
    }
  
    4% {
      opacity: 0.9;
    }
  
    6% {
      opacity: 0.85;
    }
  
    8% {
      opacity: 0.95;
    }
  
    10% {
      opacity: 0.9;
    }
  
    11% {
      opacity: 0.922;
    }
  
    12% {
      opacity: 0.9;
    }
  
    14% {
      opacity: 0.95;
    }
  
    16% {
      opacity: 0.98;
    }
  
    17% {
      opacity: 0.9;
    }
  
    19% {
      opacity: 0.93;
    }
  
    20% {
      opacity: 0.99;
    }
  
    24% {
      opacity: 1;
    }
  
    26% {
      opacity: 0.94;
    }
  
    28% {
      opacity: 0.98;
    }
  
    37% {
      opacity: 0.93;
    }
  
    38% {
      opacity: 0.5;
    }
  
    39% {
      opacity: 0.96;
    }
  
    42% {
      opacity: 1;
    }
  
    44% {
      opacity: 0.97;
    }
  
    46% {
      opacity: 0.94;
    }
  
    56% {
      opacity: 0.9;
    }
  
    58% {
      opacity: 0.9;
    }
  
    60% {
      opacity: 0.99;
    }
  }
.jackpot{
            @media (min-width:1025px){
                filter: drop-shadow(1px 2px 0 rgba(0, 0, 0, .75));
                display: flex;
                justify-content: center;
                flex: 0 1 50%;
                
                border-radius: 4px;
            }
            @media (max-width:1024px){
              position: absolute;
              width: 100%;
              left: 0;
              top: 35px;
            }
            &._compat{
                flex: 0 1 50%;
                position: static;
                @media (max-width:1024px){
                  flex: 1 1 auto;
                }
                //z-index: 3;
            }
        &__wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            //background-color: var(--dark-flamingo);
            background: linear-gradient(to bottom right, rgba(2, 99, 225, 1.0), rgba(235, 24, 54, 1.0));
            background-size: 400% 400%;
    animation: gradientAnimation 5s ease infinite;
    @keyframes gradientAnimation {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
      }
            border-radius: 4px;
            @media (max-width:1024px){
              border-radius: 0px;
          }
            ._compat &{
              border-radius: 0;
                flex: 1;
                //flex-direction: column;
            }
        }
        &__content{
            display: flex;
            align-items: center;
            justify-content: space-between;
            align-items: center;
            background-color: var(--dark-flamingo);
            border-radius: 4px;
            display: flex;
            margin: 2px;
            padding: 0px 5px;
            
            &._rtl{
                flex-direction: row-reverse;
            }
            @media (min-width:1025px){
              width: 475px;
            }
            @media (max-width:1024px){
                justify-content:space-between;
                align-items: center;
                
               flex: 1 1 100%;
            }
            @media (max-width:1024px){
                margin: 4px;
                padding: 0px 8px;
                flex-direction: column;
                display: flex;
                justify-content:center;
                align-items: center;
            }
            ._compat &{
                flex: 1;
                margin: 2px;
            padding: 0px 5px;
            justify-content: center;
            
            
            }
          //  @media (max-width:350px){
          //   
          //}
        }
        &__ctn{
          gap: 5px;
            display: flex;
            align-items: center;
            &_bottom{
                @media (min-width:1024px){
                    display: none;
                }
            }
            ._rtl &{
              flex-direction: row-reverse;
          }
        }
        &__name{
                background: #fff linear-gradient(180deg, transparent 60%, rgba(0, 0, 0, .75));
        
        color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: var(--white);
                line-height: 1;
                text-transform: uppercase;
                font-weight: 700;
                filter: drop-shadow(1px 2px 0 rgba(0, 0, 0, .75));
                font-size: 26px;
                //@media (max-width:1024px){
                //    &:last-child{
                //        font-size: 60px;
                //    }
                //}
        @media (max-width:1024px){
            font-size: 20px;
        }
        @media (max-width:768px){
            font-size: 18px;
           // transform: translate(0px,-25px);
        }
        ._compat &{
            margin: 0;
            font-size: 25px;
            transform: translate(0px,0px);
            @media (max-width:500px){
                font-size: 20px;
            }
        }
        }
        &__value{
          display: flex;
          //align-items: flex-end;
          align-items: center;
                background: var(--white) linear-gradient(180deg, transparent 60%, rgba(0, 0, 0, .75));
                color: transparent;
                -webkit-background-clip: text;
                -webkit-text-fill-color: var(--white);
                line-height: 1;
                text-transform: uppercase;
                font-weight: 700;
                @media (min-width:1025px){
                    ._rtl &{
                    margin-right: 15px;
                    margin-left: 4px;
                }
                    margin-left: 15px;
                    margin-right: 4px;
            }
            ._compat &{
                margin: 0;
                transform: translate(0px,0px);
            }
        }
}
</style>