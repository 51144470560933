<template>
  <div>
    <div
      id="app"
      data-app="true"
      :class="{ 'is-desktop': !$isMobile(), 'is-mobile': $isMobile() }"
    >
      <!-- '_blocked' : isBlockedCountry -->
      <!-- v-if="!isBlockedCountry && !isBlockedProduct" -->
      <jackpot-win-dialog />
      <jackpot-started />
      <jackpot-lose-dialog />
      <the-aside v-if="!$route.path.includes('/game')" />

      <!-- <keep-alive v-if="!loadingFlag" :key="keepAliveKey"
          include="Home">

            <router-view class="router_view" id="routerView" />

          </keep-alive> -->
      <keep-alive :max="5" include="Home">
        <router-view
          class="router_view"
          id="routerView"
          :key="$route.fullPath"
        />
      </keep-alive>
      <!-- <bottom-menu v-if="userInfo.id" /> -->
      <bottom-provider-list
        v-if="$isMobile() && userInfo.id && !$route.path.includes('/game')"
      />
      <transition name="fade">
        <v-overlay opacity="1" z-index="1000" v-if="loadingFlag">
          <div class="logo__container">
            <div class="logo__preloader">
              <img
                class=""
                :src="`https://minio.${domain}/ui-style/${logo}`"
              />
            </div>
          </div>
        </v-overlay>
      </transition>
      <!-- <blocked v-else /> -->
    </div>
    <ul class="bg-bubbles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
</template>
<script>
import JackpotLoseDialog from "./components/Jackpot/JackpotLoseDialog.vue";
import JackpotWinDialog from "@/components/Jackpot/JackpotWinDialog.vue";
import JackpotStarted from "@/components/Jackpot/JackpotStarted.vue";
import TheAside from "./components/TheAside.vue";
import fingerprint from "./plugins/fingerprint";
import { mapActions, mapGetters, mapState } from "vuex";
import router from "./router/router";
import axios from "axios";
import BottomMenu from "./components/BottomMenu/BottomMenu.vue";
import BottomProviderList from "./components/BottomMenu/BottomProvidersList.vue";
import { isMobile } from "mobile-device-detect";

export default {
  components: {
    // SignIn,
    //BottomMenu,
    BottomProviderList,
    TheAside,
    JackpotLoseDialog,
    JackpotWinDialog,
    JackpotStarted,
  },
  created() {
    // document.documentElement.style.setProperty("--main-flamingo", "#fc8eac");
    // if (this.isBlockedCountry || this.isBlockedProduct) {
    //   return;
    // }
  },
  name: "App",
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      logo: "styles/getLogo",
      jackpot: "getJackpot",
    }),
    // isBlockedCountry(){
    //   return this.$store.state.isBlockedCountry;
    // },
    // isBlockedProduct(){
    //   return this.$store.state.isBlockedProduct;
    // },
  },
  data() {
    return {
      domain: window.location.hostname.includes("kelt") ?
      process.env.VUE_APP_API_DOMAIN_THIRD : process.env.VUE_APP_API_DOMAIN_SECOND,
      loadingFlag: true,
      keepAliveKey: 0,
    };
  },
  watch: {
    async userInfo(val, oldVal) {
      if (val.id !== oldVal.id) {
        setTimeout(() => {
          this.keepAliveKey += 1;
        }, 400);
      }
    },
  },
  async mounted() {
    // if (this.isBlockedCountry || this.isBlockedProduct) {
    //   return;
    // }
    this.loadingFlag = true;
    if (localStorage.getItem("jwt")) {
      this.$store.dispatch("initializeWebSocket");
      if (!this.jackpot.id) {
        await this.$store.dispatch("awaitGetJackpot");
      }
    }
    let visited = false;
    if (typeof Storage !== "undefined") {
      visited = localStorage.getItem("visited");
    }
    if (typeof Storage !== "undefined") {
      localStorage.setItem("visited", "true");
    }
    await this.$store.dispatch("currencies/awaitGetCurrencies");
    await this.$store.dispatch("localization/awaitGetLocalizations");
    this.loadingFlag = false;
  },
  methods: {},
};
</script>
<style lang="scss">
.high-block {
  overflow: hidden;
}

.skeleton-full {
  .v-skeleton-loader__image {
    height: 100%;
  }
}

.logo__preloader {
  z-index: 2;
  position: relative;
  display: block;
  animation: rippleLogo 1.8s infinite linear;
  width: 150px;
  height: 150px;
  &::before {
    content: "";
    position: absolute;
    width: 110px;
    height: 110px;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    left: 50%;
    animation: ripple 1.8s linear infinite;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    z-index: 5;
  }
}

$color: #fc5185;

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba($color, 0.2), 0 0 0 1px rgba($color, 0.2),
      0 0 0 3px rgba($color, 0.4), 0 0 0 5px rgba($color, 0.4);
  }

  50% {
    box-shadow: 0 0 0 0 rgba($color, 0.1), 0 0 0 4px rgba($color, 0.1),
      0 0 0 20px rgba($color, 0), 0 0 0 30px rgba($color, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba($color, 0.1), 0 0 0 4px rgba($color, 0.1),
      0 0 0 20px rgba($color, 0), 0 0 0 30px rgba($color, 0);
  }
}

@keyframes rippleLogo {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.column__search-border-t {
  .v-input__control {
    .v-input__slot {
      padding-right: 50px !important;

      fieldset {
        //border-right: none!important;
        @media (any-hover: none) and (max-width: 992px) {
          //top: -4px!important;
        }
      }
    }
  }
}

.error--text {
  .v-input__control {
    .v-input__slot {
      fieldset {
        border: 2px solid var(--red);
      }
    }
  }
}

.theme--light.v-select .v-select__selections {
  color: inherit !important;
}

.v-dialog {
  box-shadow: none !important;
  display: flex;
  justify-content: center;
}

.v-chip.v-chip--outlined.v-chip--active:before {
  opacity: 0 !important;
}

.deposit-chips {
  .v-chip-group {
    .v-chip--active {
      color: var(--white) !important;

      .v-chip--no-color:after {
        opacity: 0;
      }
    }
  }
}

.v-messages__message {
  color: var(--red) !important;
}

.burger-menu:-webkit-scrollbar-thumb {
  height: 10px !important;
  border-radius: 10px !important;
  background-color: var(--white) !important;
}
.burger-menu {
  //@media (min-width:769px){
  //  width: auto!important;
  //  scrollbar-width: none;
  //  padding: .875rem 4.6875rem 3rem 6.75rem;
  //  -webkit-clip-path: polygon(0 0, 100% 0, 18.125rem 100%, 0 100%);
  //  clip-path: polygon(0 0, 100% 0, 18.125rem 100%, 0 100%);
  //
  //}
}
.v-navigation-drawer__content {
  //overflow-y: auto!important;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}

.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  display: none;
}

.v-navigation-drawer__content::-webkit-scrollbar-track {
  display: none;
}

.games__scroller::-webkit-scrollbar {
  //display: none;
  height: 5px !important;
  border-radius: 10px !important;
  background-color: var(--gray) !important;
}

.games__scroller::-webkit-scrollbar-thumb {
  height: 5px !important;
  border-radius: 10px !important;
  background-color: var(--dark-flamingo) !important;
}

.games__scroller::-webkit-scrollbar-track {
  // display: none;
}

.eternal-notif {
  .v-alert__wrapper {
    .v-alert__icon {
      margin-right: 10px;
    }
  }

  .v-alert__content {
    display: flex;

    .eternal-notif__message {
      text-align: left;
    }

    .eternal-notif__close {
      cursor: pointer;
      align-self: flex-start;
      font-size: 26px;
    }
  }
}

.all-inputs {
  .v-input__icon--append {
    .v-icon {
      color: var(--white) !important;
    }
  }
}

.selectMain {
  &.v-input--is-disabled {
    opacity: 0.5;
  }
  &._center {
    .v-list {
      .v-list-item {
        justify-content: center !important;
      }
    }
  }
  .v-list {
    padding: 0;

    .v-list-item {
      &::before {
        border: 1px solid hsla(0, 0%, 100%, 0.12);
        background-color: transparent !important;
      }
    }
  }

  .v-input__control {
    .v-input__slot {
      align-items: center !important;
      .v-select__slot {
        .v-label {
          color: var(--white) !important;
        }

        .v-select__selections {
          .v-select__selection {
            color: var(--white) !important;
          }
        }
      }
    }
  }
}

.select__translation-t {
  .v-input__slot {
    fieldset {
      border: 0 !important;
    }
  }
}

//.menuable__content__active{
//  text-transform: uppercase;
//
//}

.router_view {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chip-items-reality {
  .v-slide-group__wrapper {
    .v-slide-group__content {
      justify-content: center;
    }
  }
}

.v-toolbar__content {
  //max-width: 1210px;
  //margin: 0px auto;
  padding: 0 !important;
}

.header_wrapper {
  .app__bar {
    @media (max-width: 992px) {
      height: 50px !important;
    }

    @media (max-height: 500px) and (orientation: landscape) and (max-width: 992px) {
      height: 50px !important;
    }
  }

  .v-toolbar__content {
    @media (max-width: 992px) {
      height: 50px !important;
    }

    @media (max-height: 500px) and (orientation: landscape) and (max-width: 992px) {
      height: 50px !important;
    }
  }
}

.v-list-item--link {
  &:not(:first-child):before {
    background-color: var(--red) !important;
    width: 100% !important;
    height: 2px !important;
    opacity: 1 !important;
  }
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  //min-height: 20px!important;
}

.custom-switch {
  .theme--light.v-input--switch.v-input--is-disabled:not(.v-input--is-dirty)
    .v-input--switch__track {
    color: var(--white) !important;
  }

  .v-input__control {
    .v-input__slot {
      .v-input--selection-controls__input {
        .v-input--selection-controls__ripple {
          &::before {
            opacity: 0;
          }
        }

        .v-input--switch__track {
          opacity: 1;
          background-color: var(--gray);

          &.green--text.text--accent-4 {
            opacity: 0.3;
            background-color: currentColor;
          }
        }
      }
    }
  }

  &_deposit {
    .v-input__control {
      .v-input__slot {
        .v-input--selection-controls__input {
          .v-input--selection-controls__ripple {
            &::before {
              opacity: 0;
            }
          }

          .v-input--switch__thumb {
            background-color: var(--white) !important;
          }

          .v-input--switch__track {
            opacity: 1 !important;

            &.green--text.text--accent-4 {
              opacity: 0.3;
              background-color: var(--green-success) !important;
            }
          }
        }
      }
    }
  }
}

input {
  text-overflow: ellipsis;
}

.chip-items {
  .v-slide-group__prev {
    &.v-slide-group__prev--disabled {
      display: none;
    }
    .v-icon {
      .v-icon__svg {
        fill: var(--main-flamingo) !important;
      }
    }
  }

  .v-slide-group__next {
    .v-icon {
      .v-icon__svg {
        fill: var(--main-flamingo) !important;
      }
    }
  }
}

.dialog-email-content {
  &__chip-items {
    .v-slide-group__wrapper {
      display: block !important;

      .v-slide-group__content {
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        display: grid !important;
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  &__chip-item {
    border-radius: 6px !important;
    justify-content: center;
    align-items: center;
    min-height: 44px;
    border: none !important;

    span {
      color: #161421 !important;
    }
  }
}

.deposit-chip {
  .v-slide-group__wrapper {
    display: block !important;

    .v-slide-group__content {
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      display: grid !important;
      grid-template-columns: repeat(4, 1fr);
      padding: 0;
    }
  }

  &__item {
    border-radius: 6px !important;
    justify-content: center;
    align-items: center;
    margin: 0 !important;

    span {
      color: #161421 !important;
    }
  }
}

.v-input__append-inner {
  align-self: center !important;
  margin: 0 !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@import "./assets/scss/main.scss";

body {
  // background: url('./assets/images/mafia_site_bg.png'); --> main background
  background-color: var(--main-flamingo);
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
  touch-action: pan-x;
  width: 100%;
  //min-height: -webkit-fill-available;
  @media (orientation: portrait) and (max-width: 1024px) {
    background-image: radial-gradient(
        ellipse 50% 150% at left,
        rgba(0, 49, 173, 0.4) 0,
        transparent 100%
      ),
      radial-gradient(
        ellipse 50% 150% at right,
        rgba(0, 49, 173, 0.4) 0,
        transparent 100%
      );
  }
  @media (orientation: landscape) and (max-width: 1024px) {
    background-image: radial-gradient(
        ellipse 150% 5% at top,
        rgba(49, 108, 255, 0.25) 0,
        transparent 100%
      ),
      radial-gradient(
        ellipse 150% 5% at bottom,
        rgba(49, 108, 255, 0.25) 0,
        transparent 100%
      ),
      radial-gradient(
        ellipse 150% 50% at top,
        rgba(0, 49, 173, 0.4) 0,
        transparent 100%
      ),
      radial-gradient(
        ellipse 150% 50% at bottom,
        rgba(0, 49, 173, 0.4) 0,
        transparent 100%
      );
  }
}
.is-desktop {
  height: 100vh;
}
html {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
  overflow: auto !important;
}
* {
  caret-color: var(--white) !important;
}

#app {
  //font-family: "Montserrat", sans-serif;
  //font-family: "Inter",Helvetica,Arial,sans-serif;
  font-family: "Open Sans", sans-serif;
  position: relative;
  z-index: 2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  color: var(--black);
  //background-color: var(--white);
  //width: 100vw;
  //height: max-content;
  &._blocked {
    height: 100%;
  }
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow-x: hidden;
}
//========================================================================================================================================================
// pc-search
.search-pc__input {
  .v-input__control {
    .v-input__slot {
      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }
}

//========================================================================================================================================================

.main-input {
  color: var(--main-flamingo) !important;
  border-color: var(--main-flamingo) !important;

  .v-input__slot {
    .v-input__prepend-inner {
      .v-icon {
        .error--text & {
          color: var(--red) !important;
        }
      }
    }

    &::before {
      border-color: var(--main-flamingo) !important;
    }

    &::after {
      border-color: var(--main-flamingo) !important;
    }

    fieldset {
      color: var(--main-flamingo) !important;
      border-color: var(--main-flamingo) !important;

      .error--text & {
        color: var(--red) !important;
      }
    }

    .v-text-field__slot {
      .v-label {
        color: var(--main-flamingo) !important;
      }

      input {
        color: var(--main-flamingo) !important;
        caret-color: var(--main-flamingo) !important;
      }
    }
  }

  .v-text-field__details {
  }
}
.links-label {
  .link-label {
    //border: 1px solid var(--red);
    border-radius: 6px !important;
    background-color: var(--dark-flamingo);
    transition: all 0.15s;
    .v-chip__content {
      color: var(--black);
    }
    @media (any-hover: hover) {
      &:hover {
        background-color: var(--dark-flamingo) !important;
        .v-chip__content {
          color: var(--white);
        }
      }
    }
    &._active {
      color: var(--dark-flamingo);
      .v-chip__content {
        color: var(--white);
      }
    }
  }
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin: 0 6px !important;
}
.input-sign {
  transition: all 0.3s !important;
  margin-bottom: 0px !important;
  &._error {
    margin-bottom: 15px !important;
  }
}
.small-text {
  color: var(--gray-text);
  font-size: 14px;
  font-weight: 400;
}
.main-input {
  &.error--text {
    .v-input__slot {
      background-color: #ffe0e9;
    }

    .v-input__slot {
      .v-input__prepend-inner {
        .v-icon {
          color: var(--red) !important;
        }
      }

      fieldset {
        color: var(--red) !important;
        border-color: var(--red) !important;
      }

      .v-text-field__slot {
        .v-label {
          color: var(--red) !important;
        }
      }
    }

    .v-text-field__details {
    }
  }
  .v-text-field__slot {
    input {
      &::placeholder {
        color: var(--gray) !important;
      }
    }
  }
}
.input-auto {
  transition: margin-bottom 0.3s;
  &.v-input {
    margin-bottom: 9px !important;
  }
  &.error--text {
    margin-bottom: 0px !important;
  }
  &.v-input--has-state {
    margin-bottom: 9px !important;
  }
}
.input-colors {
  &.error--text {
    .v-input__control {
    }

    .v-input__slot {
      .v-input__prepend-inner {
        .v-icon {
          color: var(--red) !important;
        }
      }

      fieldset {
        color: var(--red) !important;
        border-color: var(--red) !important;
      }

      .v-text-field__slot {
        .v-label {
          color: var(--red) !important;
        }

        input {
        }
      }
    }

    .v-text-field__details {
    }
  }

  &.success--text {
    .v-input__slot {
      .v-input__prepend-inner {
        .v-icon {
          color: #5eeb64 !important;
        }
      }

      fieldset {
        color: #5eeb64 !important;
        border-color: #5eeb64 !important;
      }

      .v-text-field__slot {
        .v-label {
          color: #5eeb64 !important;
        }

        input {
          color: #5eeb64 !important;
        }
      }
    }

    .v-text-field__details {
    }
  }
}

.all-shadow-hover {
  transition: box-shadow 0.15s ease;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: transparent !important;
}

.passportDialog {
  border-radius: 16px !important;
}

.whiteDialog {
  background-color: var(--white);
}

.black-input {
  .v-input__control {
    .v-input__slot {
      &::before {
        border-color: var(--white-design) !important;
      }

      &::after {
        transform: scaleX(0) !important;
        border-color: var(--white-design) !important;
      }

      .v-text-field__slot {
        input {
          color: var(--dark-flamingo) !important;
          caret-color: var(--dark-flamingo) !important;
        }
      }
    }
  }
}

.notification-h {
  border-radius: 0px !important;

  .v-alert__wrapper {
    justify-content: center;
    align-items: center;
  }

  .v-alert__icon {
    align-self: center;
  }

  .v-alert__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: initial;
  }
}

.hover-link {
  color: inherit !important;

  &_green {
    color: var(--green-success) !important;
  }

  @media (any-hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
}

.slider-fade {
  overflow: hidden !important;
}

.tmb-style {
  &_fz24 {
    font-size: 24px;
  }

  &_fz16 {
    font-size: 16px;
  }

  &_fz14 {
    font-size: 14px;
  }

  &_fz12 {
    font-size: 12px;
  }

  &_white {
    color: var(--white);
  }

  &_bg-light {
    background-color: var(--light-flamingo);
  }

  &_bg-dark {
    background-color: var(--dark-flamingo);
  }

  &_bg-main {
    background-color: var(--main-flamingo);
  }

  &_td-none {
    text-decoration: none;
  }

  &_title-header {
    display: grid;
    grid-template-columns: 36px 1fr 36px;
    align-items: center;
    width: 100%;
    padding: 10px 16px !important;
    border-radius: 0px !important;
    position: relative;

    &_logo {
      grid-template-columns: 65px 1fr 36px;
    }

    &__content {
      grid-column: 2/3;
      max-width: 100%;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;

      span {
        color: var(--white);
        font-size: 15px;
        white-space: nowrap;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__close {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.blur-overlay {
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.322);
}

.side-gradient-right,
.side-gradient-left {
  position: absolute;
  height: 100%;
  top: 0;
  pointer-events: none;
  z-index: 2;

  @media (max-width: 1140px) {
    display: none;
  }
}

.side-gradient-right {
  background: linear-gradient(
    90deg,
    rgba(43, 39, 64, 0) 40%,
    var(--main-flamingo)
  );
  right: 0;
  width: 50px;

  &_dark {
    width: 100px;
    background: linear-gradient(
      90deg,
      rgba(43, 39, 64, 0) 40%,
      var(--dark-flamingo)
    );
  }
}

.side-gradient-left {
  background: linear-gradient(90deg, var(--main-flamingo), rgba(43, 39, 64, 0));
  left: 0;
  width: 6px;

  &_dark {
    width: 6px;
    background: linear-gradient(
      90deg,
      var(--dark-flamingo),
      rgba(43, 39, 64, 0)
    );
  }
}

.v-progress-linear .v-progress-linear__indeterminate--active {
  .long,
  .short {
    border-color: var(--red) !important;
    background-color: var(--red) !important;
  }
}
</style>
