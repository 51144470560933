import "@babel/polyfill";
import Vue from "vue";
import App from "./App.vue";
import blockedComponent from "../blocked-region-page/blocked.vue";
import router from "./router/router";
import store from "./store/store.js";
import vuetify from "./plugins/vuetify.js";
// import "./plugins/google-auth.js";
import "./plugins/fa-icons";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import axios from "axios";
import { VLazyImagePlugin } from "v-lazy-image";
import VueCookie from "vue-cookie";
Vue.config.productionTip = false;
Vue.use(VLazyImagePlugin);
Vue.use(VueCookie);
import VueLazyload from "vue-lazyload";

import VueMobileDetection from "vue-mobile-detection";

Vue.use(VueMobileDetection);
Vue.use(VueLazyload, {
  attempt: 2,
});
import NoCyrillic from "./directives/no-cyrillic";

Vue.directive("no-cyrillic", NoCyrillic);
// import chat from "./plugins/jivo-chat-functions";

// Vue.prototype.$jivoChat = chat;
import VueRouter from "vue-router";
import { sync } from "vuex-router-sync";
Vue.use(VueRouter);
sync(store, router);

import "intersection-observer";

import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

Vue.component("RecycleScroller", RecycleScroller);
import * as Fingerprint2 from "fingerprintjs2";

Vue.prototype.$fingerprint = Fingerprint2;

// import "./plugins/swHandler";
import { i18nPromise } from "./plugins/i18n";
import setFavicon from "./setFavicon.js";
async function createVueInstance() {
  const domain = window.location.hostname.includes("kelt")
    ? process.env.VUE_APP_API_DOMAIN_THIRD
    : process.env.VUE_APP_API_DOMAIN_SECOND;
  await store.dispatch("styles/getUI");
  const variables = store.getters["styles/getColorVariables"];
  document.title = store.getters["styles/getTitle"];
  variables.forEach((element) => {
    document.documentElement.style.setProperty(element.key, element.value);
  });
  const favicon = store.getters["styles/getFavicon"];
  const faviconUrl = `https://minio.${domain}/ui-style/${favicon}`;
  setFavicon(faviconUrl);
  if (localStorage.getItem("jwt")) {
    await store.dispatch("awaitGetUserInfo");
  }
  const i18n = await i18nPromise();

  axios.interceptors.response.use(
    function (response) {
      // success response
      // console.log("response = ", response);
      // if (
      //   response.data &&
      //   response.data.error === 6000 &&
      //   response.data.redirect_url === "https://vip-casino.pro/blocked"
      // ) {
      //   store.commit("set", { type: "isBlockedCountry", items: true });
      // } else if (response.data && response.data.error === 6000) {
      //   if (response.data.redirect_url) {
      //     window.location.href = response.data.redirect_url;
      //   }
      // } else if (response.data && response.data.error === 6001) {
      //   // router.push("/maintenance");
      //   store.commit("set", { type: "isBlockedProduct", items: true });
      // }
      return response;
    },
    function (error) {
      // error
      // console.log("error");
      // || error.response.status === 403
      if (error.response && error.response.status === 401) {
        store.dispatch("logOut");
      }
      return Promise.reject(error);
    }
  );

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: function (h) {
      return h(App);
    },
  }).$mount("#app");
}

createVueInstance().catch((error) => {
  console.error("Failed to initialize Vue instance:", error);
});
