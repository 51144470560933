export default function setFavicon(url) {
  const existingIcons = document.querySelectorAll('link[rel*="icon"]');
  existingIcons.forEach((icon) => icon.parentNode.removeChild(icon));

  const head = document.head;

  const link16 = document.createElement("link");
  link16.rel = "icon";
  link16.type = "image/png";
  link16.href = url;
  link16.sizes = "16x16";
  head.appendChild(link16);

  const link32 = document.createElement("link");
  link32.rel = "icon";
  link32.type = "image/png";
  link32.href = url;
  link32.sizes = "32x32";
  head.appendChild(link32);

  const link96 = document.createElement("link");
  link96.rel = "icon";
  link96.type = "image/png";
  link96.href = url;
  link96.sizes = "96x96";
  head.appendChild(link96);

  const appleTouchIcon = document.createElement("link");
  appleTouchIcon.rel = "apple-touch-icon";
  appleTouchIcon.href = url;
  appleTouchIcon.sizes = "180x180";
  head.appendChild(appleTouchIcon);
}
