<template>
    <div class="">
    <div class="search" :style="{display: isSearch ? 'block' : 'none'}">
        <input type="text"
        v-no-cyrillic
        ref="search"
        @input="debounceFilterGames"
        v-model="filters.name"
        :placeholder="$t('search')"
        class="search__input"
        >
        <div v-if="filters.name" @click="clearFilter" class="search__reset-button">
            <v-icon color="var(--white)"> clear </v-icon>
        </div>
    </div>
    <button @click="openSearch" 
    ref="searchButton"
    type="button" class="search-button button">
        <v-icon color="var(--white)">search</v-icon>
    </button>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { debounce } from 'lodash'
    export default {
        name: "TheSearch",
        data() {
            return {
                isSearch: false,
                filters: {
                    page: 1,
                    size: 50,
                    name: null,
                    provider: null,
                    is_favourite: false,
                },
                holdFavoriteGamesName: '',
                holdAllGamesName: '',
            }
        },
        computed:{
            ...mapGetters({
                providers: 'gameModule/getProviders',
                currentTab: 'gameModule/getCurrentTab'
            }),
        },
        mounted(){
            document.addEventListener("click", this.closeSearchOnClickOutside);
        },
        beforeDestroy() {
            document.removeEventListener("click", this.closeSearchOnClickOutside);
        },
        watch: {
            currentTab(newV){
                if (newV) {
                    // this.filterGames();
                    this.filters.name = null;
                    if (newV === 'favorite') {
                        this.filters.name = this.holdFavoriteGamesName;
                    } else if (newV === 'all') {
                        this.filters.name = this.holdAllGamesName;
                    }
                }
            },
        },
        methods:{
            closeSearchOnClickOutside(e) {
                if (!this.$refs.search.contains(e.target) && !this.$refs.searchButton.contains(e.target)) {
                    this.isSearch = false
                }
            },
            openSearch() {
              this.isSearch = !this.isSearch;
              this.$nextTick(() => {
                  this.$refs.search.focus();
              })
            },
            debounceFilterGames: debounce(function () {
                this.filterGames()
            }, 550),
            clearFilter() {
                this.filters.name = null
                if (this.currentTab === 'all') {
                    this.filters.provider = this.providers.items[0].string_id
                }
                this.filterGames()
                this.filters.provider = null;
            },
            async filterGames(){
                let endpoint;
                if (this.filters.name) {
                        this.filters.name = this.filters.name.trim()
                 }
                if (this.currentTab === 'favorite') {
                    this.filters.is_favourite = true;
                    endpoint = 'favoriteGames/awaitGetFavoriteGames'
                    this.holdFavoriteGamesName = this.filters.name;
                } else{
                    this.filters.is_favourite = null;
                    endpoint = 'gameModule/awaitGetGames'
                    this.holdAllGamesName = this.filters.name;
                }
                
                this.$store.commit('gameModule/set', {type: 'loadingProviders', items: true})
                await this.$store.dispatch(endpoint, this.filters);
                this.$store.commit('gameModule/set', {type: 'loadingProviders', items: false})
                this.$store.commit('gameModule/set', {type: 'providersSearchTrigger', items: true})
            },
        },
    }
</script>

<style lang="scss" scoped>
.search {
    top: 73px;
    left: 0;
    right: 0;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    z-index: 800;
    width: 100%;
    @media (max-width:500px){
        top: 63px;
    }
		&__input {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 0 0 0 10px;
            margin: 0;
            border-radius: 0;
            color: var(--white);
            font-size: 12px;
            border-bottom: 1px solid var(--white);
            border-top: 1px solid var(--white);
            border-left: none;
            border-right: none;
            text-align: left;
            outline: none;
            background-color: #000;
            background-image: radial-gradient(ellipse 50% 150% at left, rgba(0, 49, 173, .4) 0, transparent 100%), radial-gradient(ellipse 50% 150% at right, rgba(0, 49, 173, .4) 0, transparent 100%);
        
        &::placeholder{
            color: var(--white);
        }
    }

		&__reset-button {
            position: absolute;
            right: 0;
            top: 2px;
            width: 36px;
            height: 36px;
            cursor: pointer;
            z-index: 1000;
            display: flex;
            justify-content:center;align-items: center;
		}
}
.search-button {
    position: fixed;
    bottom: 40px;
    left: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    z-index: 800;
    outline: none;
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 50px;
    border: none;
    background-color: #001854;
    color: var(--white);
    display: flex;
    justify-content:center;
    align-items: center;
    i{
        font-size: 38px;
    }
    &:hover{
        background: radial-gradient(circle, #002ea1 0, #001854 100%);
    }
}
</style>