<template>
    <div class="nav nav--translucent nav--compact nav--right"
    :class="{ 'nav--hide': !isOpen }"
    v-click-outside="toCloseMenu"
    >
        
    <!-- :class="openMenuButton" -->
        <button class="nav__btn nav__btn--hide btn btn--icon"
        @click="toOpenMenu">
            <v-icon color="var(--white)">
                {{ !isOpen ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
            </v-icon>
        </button>
        <button class="nav__btn nav__btn--back button button_icon" @click="$router.push('/')">
            <v-icon color="var(--white)">mdi-login</v-icon>
        </button>
        <button class="nav__btn nav__btn--star button button_icon" @click.stop="toToggleFavoriteGame(game)">
            <v-icon color="var(--white)">
                {{ game && game.is_favourite ? 'favorite' : 'mdi-heart-outline' }}

            </v-icon>
        </button>
        <button v-if="!isIOS" class="nav__btn nav__btn--fullscreen button button_icon" @click.stop="$emit('fullscreen')">
            <v-icon color="var(--white)">
                {{ IsFullScreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}


            </v-icon>
        </button>
    </div>
</template>

<script>
    export default {
        name: "MobileHeader",
        props:{
            IsFullScreen:{
                type: Boolean,
            },
        },
        watch: {
            currentGame: {
                handler(newV) {
                    this.game = newV
                },
                immediate: true,
                deep: true
            }
        },
        destroyed() {
            this.toCloseMenu();
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
      vm.toCloseMenu();
    });
        },
        data() {
            return {
                isIOS: /iPhone|iPad|iPod/i.test(navigator.userAgent),
                game: {},
            }
        },
        async mounted() {
            const id = this.getGameByUrl();
            await this.$store.dispatch("gameModule/awaitGetGame", id);
        },
        methods:{
            getGameByUrl() {
      const url = window.location.pathname;
      const parts = url.split('/'); 
      const gameID = parts[parts.length - 1];
      return gameID;
    },
            toCloseMenu(){
                this.$store.commit('set', { type: 'openMenu', items: false })
            },
            toOpenMenu(){
                this.$store.commit('set', { type: 'openMenu', items: !this.isOpen })
            },
            async toToggleFavoriteGame(game){
                game.is_favourite = !game.is_favourite
                await this.$store.dispatch('favoriteGames/awaitToggleFavoriteGame', {id: game.id, is: game.is_favourite});
                // await this.$store.dispatch("gameModule/awaitGetGame", this.id);
            }
        },
        computed:{
            currentGame() {
                return this.$store.state.gameModule.currentGame
            },
            isOpen(){
                return this.$store.state.openMenu
            },
        },
    }
</script>

<style lang="scss" scoped>
.nav {
    top: 5%;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    position: absolute;
    -webkit-transition: background .25s ease, -webkit-transform .5s ease;
    transition: background .25s ease, -webkit-transform .5s ease;
    transition: background .25s ease, transform .5s ease;
    transition: background .25s ease, transform .5s ease, -webkit-transform .5s ease;
    overflow: hidden;
    z-index: 110;

    &__btn {
        width: 58px;
        display: flex;
        justify-content:center;align-items: center;
        svg{
            width: 50%;
            height: 50%;
        }
    }
    &__btn--hide {
        transform: scale(1.4);
        
    }
    &__btn--fullscreen {
        transform: scale(1.4);
        
    }
    &__btn--back {
    }
}
.nav--translucent {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 0 0 5px 5px;
}
.nav--compact {
    height: 50px;
    .nav__btn--hide {
        width: 28px;
    padding: 0 5.6px;
    margin-right: 5.6px;
    }
}
.nav--right {
    right: 0;
    padding-right: 4px;
    &.nav--hide{
        transform: translateX(calc(100% - 32px))
    }
}
.button {

    &_icon {
    }
}


</style>