<template>
    <div class="desktop-menu">
        <div class="desktop-menu__items">
                <button
                v-for="category in categories" :key="category.id"
                type="button"
                @click="selectCategory(category)"
                :class="{ '_is-active' : isCurrentTab(category.code) }"
                class="desktop-menu__item button button_small button_light"
                >{{ category.name }}
            </button>
            <the-search
            ></the-search>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import TheSearch from '@/components/Search/TheSearch.vue'
import { debounce } from 'lodash'
    export default {
        name: "DesktopMenu",
        components:{
            TheSearch,
        },
        data() {
            return {
                categories: [
                    {
                        id: 1,
                        name: this.$t('all'),
                        code: 'all'
                    },
                    {
                        id: 2,
                        name: this.$t('favourites'),
                        code: 'favorite'
                    },
                ],
                
            }
        },
        computed:{
            ...mapGetters({
                currentTab: 'gameModule/getCurrentTab'
            })
        },
        methods:{
            isCurrentTab(code){
                return code === this.currentTab
            },
            selectCategory(category){
                this.$store.commit('gameModule/set',
                {type: 'currentTab', items: category.code}

                )
            },
        },
    }
</script>

<style lang="scss" scoped>
.desktop-menu {
@media (min-width:1366px){
      padding: 0 150px;
}
height: 64px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 80px;
    &__items {
        display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 4px 0;
    }

    &__item {
        width: 120px;
    height: 60px;
    padding: 4px 20px;
    color: var(--white);
    background: var(--main-flamingo);
background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(15%, rgba(118, 16, 144, 0.5)), color-stop(30%, rgba(121, 16, 144, 0.85)), color-stop(50%, #831090), color-stop(70%, rgba(97, 16, 144, 0.85)), color-stop(85%, rgba(140, 16, 144, 0.5)), to(transparent));
background: linear-gradient(180deg, transparent, rgba(138, 16, 144, 0.5) 15%, rgba(127, 16, 144, 0.85) 30%, #5d1090 50%, rgba(99, 16, 144, 0.85) 70%, rgba(106, 16, 144, 0.5) 85%, transparent);
    
    border-width: 1px;
    &._is-active{
        background: transparent;
        border-image-source: linear-gradient(180deg, transparent, hsla(0, 0%, 100%, .75), transparent);
    background: linear-gradient(180deg, transparent, rgba(84, 0, 66, 0.5) 15%, rgba(89, 10, 109, 0.85) 100%, transparent);
    }
}
}


</style>