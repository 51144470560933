<template>
    <v-bottom-navigation app dense height="20" background-color="rgba(141, 36, 170, 0.7)" class="b-nav" ref="bottomNav">
        <transition name="bottomList">
        <bottom-providers-list v-if="activeCategory" :class="activeCategory ? 'is-active' : null" />
    </transition>
            <div class="b-nav__item" @click="toOpenProviderList">
                <v-icon color="var(--white)">
                    {{ !activeCategory ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
            </div>
        <v-spacer></v-spacer>
    </v-bottom-navigation>
</template>

<script>
import BottomProvidersList from '@/components/BottomMenu/BottomProvidersList'
export default {
    name: "BottomMenu",
    components:{
        BottomProvidersList,
    },
    data() {
        return {
            activeCategory: false,
            isHaveLastGame: false,
            categoryList: [
                {
                    vIcon: true,
                    menu: "providers",
                },
            ],
        };
    },
    computed: {
  },
    created(){
    this.$root.$on('closeBottomGameList', this.closeBottomGameList);
  },
    mounted() {
      document.addEventListener('click', this.clickOutside);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.clickOutside);
    },
    methods: {
    //    clickOutside(event) {
     //      const bottomNav = this.$refs.bottomNav;
     //      if ((bottomNav && !bottomNav.$el.contains(event.target))) {
     //        this.activeCategory = null;
     //      }
     //  
    //},
    toOpenProviderList() {
        console.log('click');
        
                    this.activeCategory = !this.activeCategory;
                }
    },
};
</script>

<style lang="scss" scoped>
.bottomList-enter-active, .bottomList-leave-active {
    transition: opacity 0.3s, bottom 0.3s linear;
  }
  
  .bottomList-enter, .bottomList-leave-to {
    opacity: 0;
    bottom: -110px;
  }
  
  .bottomList-leave-active {
    opacity: 0;
    bottom: -110px;
  }
  
  .bottomList-enter-to, .bottomList-leave {
    opacity: 1;
    bottom: 40px;
  }

ul,
li>* {
    list-style: none;
    padding: 0 !important;
}

.b-nav {
    backdrop-filter: blur(50px);
    z-index: 5!important;
    position: relative;
    @media (min-width:1025px){
          display: none!important;
    }
    &__item {
        display: flex;
        justify-content:center;align-items: center;
        position: absolute;
        top: -20px;
        right: 0;
        background-color: #8d24aa;
        width: 20px;
        height: 20px;
        .v-icon{
            width: 100%;
            height: 100%;
            &::before, &::after{
                font-size: 20px;
            }
        }
    }
}

.item-category {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px 13px!important;
    transition: background-color 0.3s ease;
    width: 44px;
    &:hover {
        background-color: hsla(0, 0%, 100%, 0.2);
        color: var(--white) !important;
    }
    
    ._active &{
        background-color: hsla(0, 0%, 100%, 0.2);
        color: var(--white) !important;
        
    }
    &__icon {
        color: #9ea4ae !important;
        fill: var(--white);
    }
}
</style>
