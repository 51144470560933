<template>
 
  <div class="sign-form">
      <div class="sign-form__ctn">
        <!-- <v-col class="pb-0 fw-500" style="font-size: 20px;color: var(--black);">
          {{$i18n.t('enterSite')}}
        </v-col> -->
        <div class="sign-form__content">
          <div class="sign-form__inputs">
  
            <v-row>
              <v-col class="column-input">
                <!-- -->
                <v-text-field
                    autofocus
                    hide-details
                    outlined
                    class="main-input input-sign"
                    :class="{'_error' : errorLoginFlag}"
                    :placeholder="$i18n.t('userName')" 
                    v-model="login"
                    type="text"
                    :error="errorLoginFlag"
                    @input="resetErrors"
                    @blur="checkIsField('login')"
                    @keydown.enter="signIn"
                    dense
                >
                  <v-icon slot="prepend-inner" color="var(--main-flamingo)">
                    person
                  </v-icon>
                </v-text-field>
                <span class="hint" :class="{'_visible' : errorLoginFlag}"> {{ $t('requiredField') }}</span>
              </v-col>
            </v-row>
                <v-row class="mt-0">
              <v-col class="column-input">
                <v-text-field
                    outlined
                    hide-details
                    class="main-input input-sign"
                    :class="{'_error' : errorPasswordFlag}"
                    :placeholder="$i18n.t('password')"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password"
                    :error="errorPasswordFlag"
                    :append-icon="showPassword ? 'visibility_off' : 'visibility'" 
                    @click:append="showPassword = !showPassword"
                    @input="resetErrors"
                    @blur="checkIsField('password')"
                    @keydown.enter="signIn"
                    dense
                >
                  <v-icon slot="prepend-inner" color="var(--main-flamingo)">
                    lock
                  </v-icon>
                </v-text-field>
                <span class="hint" :class="{'_visible' : errorPasswordFlag}"> {{ $t('requiredField') }}</span>
              </v-col>
            </v-row>
          </div>
          <div class="sign-form__actions">
  
            <v-row>
              <v-col style="" class="column-input " :class="{'_error' : errorFlag}">
                <button
                :disabled="loadingFlag"
                 @click="signIn"
                 class="sign-button"
                >
                {{$i18n.t('enter')}} 
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <!-- <v-btn :loading="loadingFlag" class="all-buttons-fw all-buttons-fw_green"
                
                @click="signIn">
                  {{$i18n.t('enter')}}
                </v-btn> -->
                <span class="hint hint-all" :class="{'_visible-all' : errorFlag}"> {{ textBanPlayer }}</span>
              </v-col>
            </v-row>
          </div>
        </div>
        <ul class="bg-bubbles _dark" v-if="$isMobile()">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
          <transition name="fade">

            <preloader isOverlay v-if="loadingFlag"/>
          </transition>
      </div>

    </div>
</template>

<script>
import Preloader from "@/components/Preloader";
  import fingerprint from "../plugins/fingerprint";
  import { mapGetters } from "vuex";
  import axios from "axios";
  export default {
    name: "SignIn",
    components:{
      Preloader,
    },
    data(){
      return{
        loadingFlag: false,
        login:'',
        password:'',
        errorFlag: false,
        errorLoginFlag: false,
        errorPasswordFlag: false,
        showPassword: false,
        textBanPlayer: '',
      }
    },
    computed:{
      ...mapGetters({
        userInfo: 'getUserInfo',
      }),
    },
    methods:{
      async moveHome() {
      this.$store.commit("setSignInModal", false);
      this.errorLoginFlag = false;
      this.errorPasswordFlag = false;
      this.errorFlag = false;
      this.login = '';
      this.password = '';
      await this.$router.push('/');
      // this.$router.go(-1);
    },
      async signIn(){
        //signIn
        this.errorFlag = false;
        this.loadingFlag = true;
        this.checkIsField('password');
        if ((this.errorPasswordFlag || this.errorLoginFlag)) {
          return this.loadingFlag = false;
        }
        try {
          const res = await this.$store.dispatch('singIn',{
            username: this.login,
            password: this.password,
            fingerprint: await fingerprint.get(),
          });
          if (res) {
            // this.$commit('JACKPOT_NULL')  
            this.$store.dispatch('initializeWebSocket');
            await this.$store.dispatch("awaitGetUserInfo");
            await this.$store.dispatch('awaitGetJackpot')
            setTimeout(() => {
              this.$store.commit('gameModule/set', {type: 'providersSearchTrigger', items: true})
            }, 250);
          this.login = '';
          this.password = '';
            await this.$router.push('/');
        } 
        } catch (error) {
          this.errorFlag = true;
          if (error.response.status === 403) {
          if (error.response.data.detail.loc === 'auth') {
            this.textBanPlayer = this.$t('smthWrong');
          } else if (error.response.data.detail.loc === 'block_permanent') {
            this.textBanPlayer = this.$t('playerHavePermanentBan') 
          } else if (error.response.data.detail.loc === 'block_temporary') {
            this.textBanPlayer = this.$t('playerHaveTemporaryBan') 
          } else if (error.response.data.detail.loc === 'is_active') {
            this.textBanPlayer = this.$t('playerIsInactive') 
          }
        } else{
          this.textBanPlayer = '---';
        }
          
        } finally{
          this.loadingFlag = false;
        }
      },
      resetErrors(){
        this.errorLoginFlag = false;
        this.errorPasswordFlag = false;
        this.errorFlag = false;
      },
      checkIsField(input) {
      if (input === 'login') {
        if (this.login.trim().length === 0) {
          this.errorLoginFlag = true;
        } else {
          this.errorLoginFlag = false;
        }
      }
      if (input === 'password') {
        if (this.password.trim().length === 0) {
          this.errorPasswordFlag = true;
        } else {
          this.errorPasswordFlag = false;
        }
      }
    },
    }
  }
</script>

<style lang="scss" scoped>
::v-deep input:-webkit-autofill,
::v-deep input:-webkit-autofill:hover, 
::v-deep input:-webkit-autofill:focus, 
::v-deep input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
}
.sign-button{

}
.sign-button{
  width: max-content;
    min-width: 200px;
  border-radius: 5px;
  height:45px;
  background: linear-gradient(to left top, #33bd11 50%, var(--green-success) 50%);
  border-style: none;
  text-transform: uppercase;
  color:var(--white);
  font-size: 23px;
  letter-spacing: 3px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  position: relative;
  padding: 0px 3px;
  overflow: hidden;
  transition: all .5s;
  box-shadow: 0px 1px 2px rgba(0,0,0,.2);
  @media (any-hover: hover){
    &:hover{
      transition: all .5s;
  transform: rotate(-3deg) scale(1.1);
  box-shadow: 0px 3px 5px rgba(0,0,0,.4);
    }
  }
  span{
    position: absolute;
    display: block;
    &:nth-child(1){
      height: 3px;
  width:200px;
  top:0px;
  left:-200px;
  background: linear-gradient(to right, rgba(0,0,0,0), #f6e58d);
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  animation: span1 2s linear infinite;
  animation-delay: 1s;
  @keyframes span1{
    0%{
        left:-200px
    }
    100%{
        left:200px;
    }
  }
    }
    &:nth-child(2){
      height: 70px;
  width: 3px;
  top:-70px;
  right:0px;
  background: linear-gradient(to bottom, rgba(0,0,0,0), #f6e58d);
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  animation: span2 2s linear infinite;
  animation-delay: 2s;
  @keyframes span2{
    0%{
        top:-70px;
    }
    100%{
        top:70px;
    }
  }
    }
    &:nth-child(3){
      height:3px;
      width:200px;
      right:-200px;
      bottom: 0px;
      background: linear-gradient(to left, rgba(0,0,0,0), #f6e58d);
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
      animation: span3 2s linear infinite;
      animation-delay: 3s;
      @keyframes span3{
        0%{
            right:-200px;
        }
        100%{
            right: 200px;
        }
      }
    }
    &:nth-child(4){
        height:70px;
        width:3px;
        bottom:-70px;
        left:0px;
        background: linear-gradient(to top, rgba(0,0,0,0), #f6e58d);
        border-top-right-radius: 1px;
        border-top-left-radius: 1px;
        animation: span4 2s linear infinite;
        animation-delay: 4s;
        @keyframes span4{
          0%{
              bottom: -70px;
          }
          100%{
              bottom:70px;
          }
      }
      }
      @media (any-hover: hover){
        &:hover{
          animation-play-state: paused;
        }
      }
  }
  
}
.sign-form{
  max-width: 500px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content:center;align-items: center;
  @media (max-width:500px){
      max-width: 100%;
      max-height: 100%;
      display: block;
      padding: 0;
  }
  &__ctn{
    width: 100%;
    background-color: var(--white);
    padding: 20px 10px;
    border-radius: 16px;
    @media (max-width:500px){
      border-radius: 0px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content:center;align-items: center;
  }
}
&__content{
width: 100%;
position: relative;
z-index: 2;
}
&__inputs{
  @media (max-width:500px){
    //flex: 1 1 100%;
    width: 100%;
    margin-bottom: 15px;
      }
}
  &__actions{
    @media (max-width:500px){
      width: 100%;
  }
  }
}
.column-input{
  position: relative;
  transition: all 0.3s;
  &._error{
    margin-bottom: 15px;
  }
}
.hint{
  position: absolute;
  right: 13px;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  color: var(--red);
  justify-content: flex-end;
  font-size: 14px;
  transition: all 0.3s;
  &-all{
    right: 0px;
    transform: translate(-25%,0px);
    font-weight: bold;
  }
  &._visible{
  bottom: 5px;
  opacity: 1;
  visibility: visible;
  }
  &._visible-all{
  bottom: -24px;
  right: 50%;
  transform: translate(50%,0px);
  opacity: 1;
  visibility: visible;
  }
}
.button__close{
    position: absolute;
    top: 17px;
    left: 100%;
    transform: translate(50%,0px);
    padding: 0!important;
    cursor: pointer;
    min-width: 36px!important;
    height: 36px!important;
    box-shadow: none!important;
    align-items: center;
    background-color: var(--white)!important;
    border-radius: 6px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3)!important;
    @media (max-width:959px){
      transform: translate(-150%,-20%);
    }
    &.is-mobile{
      transform: translate(-150%,-20%);
    }
}
.btn__anim-t{
  
  transition: transform 0.3s;
  @media (any-hover: hover){
    &:hover{
      transform: rotate(-180deg);
    }
  }
}
.auth_container{
  padding: 115px 0 12px 0;
  
  flex: 1 1 auto;
  @media (min-width:959px){
    max-width: 900px;
  }
  @media (max-width:959px){
    padding: 45px 0 0 0;
  }
}
  .reg_card{
    text-align: center;
    margin-top: 5px;
    width: 100%;
    position: relative;
    transition: none !important;
    transition-timing-function: unset !important;
    overflow: visible!important;
    background-color: var(--white)!important;
      &_pc{
        z-index: 201;
        max-width: 400px!important;
        border-radius: 16px!important;
        @media (max-width:959px){
          margin-top: 50px;
        }
    }
    .is-mobile &{
      border-radius: 0px!important;
    }
  }


</style>
