<template>
    <div>
      <v-dialog class="dialog-jackpot" persistent 
      no-click-animation
      v-model="dialog" content-class="jackpot-popup"
        max-width="625">
        <v-card flat class="jackpot">
  
          <!-- <v-card-title class="jackpot__header">
            {{ $t('jackpotsDepositeError') }}
          </v-card-title> -->
            <!-- <div class="jackpot__column">
                <div class="jackpot__image">
                  <img src="@/assets/images/jackpot-win.png" alt="jackpot win">
                </div>
            </div> -->
            <div class="jackpot__column">
                <div class="jackpot__content mt-2">
                  <v-card-text class="jackpot__text">
                    <span> 
                        {{ $t('jackStartedText')}}
                        
                    </span>
                  </v-card-text>
                  <v-card-actions class="jackpot__buttons">
                    <button @click="okayButton"
                     class="jackpot__continue button button_small button_red">
                        {{ $t('jackStart') }}
                    </button>
                </v-card-actions>
                </div>
            </div>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import {mapGetters} from 'vuex'
  export default {
    name: 'JackpotStarted',
    components:{
    },
    data() {
      return {
        dialog: false,
      }
    },
    methods: {
      async okayButton() {
        this.dialog = false;
      },
    },
    watch: {
      jackpotOnGoing:{
        async handler(newV, oldV) {
          //console.log('newV', newV);
          //console.log('oldV', oldV);
          if(newV.status) {
            await this.$store.dispatch('awaitGetJackpot')
            this.dialog = true;
          } else if (newV.status === false && oldV.status === null) {
            this.dialog = false;
            this.$store.commit('JACKPOT_NULL')
          } 
          else  {
            if (oldV && newV.status === false && oldV.status) {
            this.dialog = false;
            this.$store.commit('JACKPOT_NULL')
          }
          }
        },
        immediate: true,
        deep: true
      }
    },
    
    computed: {
      ...mapGetters({
        jackpot: 'getJackpot',
        jackpotOnGoing: 'getJackpotOnGoing',
        user: 'getUserInfo',
      })
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .jackpot {
    position: relative;
    background-color: var(--dark-flamingo)!important;
    //background: linear-gradient(to bottom right, rgba(2, 99, 225, 1.0), rgba(235, 24, 54, 1.0));
    //background-size: 400% 400%;
    //animation: gradientAnimation 5s ease infinite;
      //@keyframes gradientAnimation {
      //  0% { background-position: 0% 50%; }
      //  50% { background-position: 100% 50%; }
      //  100% { background-position: 0% 50%; }
      //}
      border: 2px solid var(--red)!important;
    max-width: 400px;
    overflow: hidden;
    border-radius: 16px!important;
    @media (max-height: 500px) and (orientation: landscape) {
      max-width: 625px;
    }
  
    color: var(--white) !important;
  
    &__row {
      @media (max-height: 500px) and (orientation: landscape) {
        display: flex;
      }
    }
    &__column {
      @media (max-height: 500px) and (orientation: landscape) {
        flex: 0 1 50%;
        &:last-child{
          display: flex;
          justify-content:center;
          align-items: center;
        }
      }
    }
    &__image {
        max-width: 100%;
        height: 275px;
    
        @media (max-height: 500px) and (orientation: landscape) {
          display: flex;
        }
    
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
  
    &__header {
      background-color: var(--dark-flamingo);
      justify-content: center;
      align-items: center;
      font-size: 14px !important;
      padding: 12px 16px !important;
      word-break: unset;
      //margin-bottom: 10px;
    }
  
    &__text{
        display: flex;
        flex-direction: column;
      color: var(--white) !important;
        padding: 15px;
            p{
          font-weight: bold;
          color: var(--white);
          font-size: 35px;
          text-transform: uppercase;
          line-height: 1;
           //animation: glitch 5s linear infinite;
            //@keyframes glitch{
            //  2%,64%{
            //    transform: translate(2px,0) skew(0deg);
            //  }
            //  4%,60%{
            //    transform: translate(-2px,0) skew(0deg);
            //  }
            //  62%{
            //    transform: translate(0,0) skew(5deg); 
            //  }
            //}
              background: 50% 100% / 50% 50% no-repeat
              radial-gradient(ellipse at bottom, #fff, transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: reveal 2400ms ease-in-out forwards 800ms,
             glow 2500ms linear infinite 2000ms;

  @keyframes reveal {
    80%{
      letter-spacing: 8px;
    }
    100% {
      background-size: 300% 300%;
    }
  }
  @keyframes glow {
    40% {
      text-shadow: 0 0 8px #fff;
    }
  }
        }
      span {
        font-weight: 700;
      }
  
      @media (max-height: 500px) and (orientation: landscape) {
        p {
          margin-bottom: 0;
        }
      }
    }
  
    &__buttons {
        display: flex;
        justify-content:center;align-items: center;

      @media (max-height: 500px) and (orientation: landscape) {
        padding-bottom: 3px !important;
        padding-top: 3px !important;
      }
  
      button {
        min-width: 120px !important;
      }
    }
  
    &__continue {}
  
    &__logout {
      margin-left: 20px !important;
    }
  
  
    &__link {
      color: var(--main-flamingo);
    }
  }</style>