// styles.js
import axios from "axios";
const state = {
  logo: "",
  title: "",
  colors: {},
  favicon: "",
  jackpot_win: "",
  domain: window.location.hostname.includes("kelt")
    ? process.env.VUE_APP_API_DOMAIN_FIRST
    : process.env.VUE_APP_API_DOMAIN_SECOND,
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getLogo: (state) => state.logo,
  getColorVariables(state) {
    return Object.entries(state.colors).map(([key, value]) => ({ key, value }));
  },
  getFavicon: (state) => state.favicon,
  getTitle: (state) => state.title,
  getJackpotWin: (state) => state.jackpot_win,
};

const actions = {
  async getUI({ state, commit }) {
    await axios
      //
      .get(`/api/ui_style/?domain=${state.domain}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("set", { type: "colors", items: response.data.css_json });
          commit("set", { type: "logo", items: response.data.logo_img });
          commit("set", { type: "favicon", items: response.data.favicon_img });
          commit("set", { type: "title", items: response.data.title });
          commit("set", {
            type: "jackpot_win",
            items: response.data.jackpot_win_img,
          });
        }
      })
      .catch((error) => {});
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
