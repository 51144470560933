<template>
    <div class="particles">
      <canvas ref="canvas"></canvas>
    </div>
  </template>
  
  <script>
  export default {
    name: "Particles",
    data() {
      return {
        canvas: null,
        ctx: null,
        particles: [],
        rd: null,
        ae: true,
        tick: 0,
        frame: 0,
        p: [],
        pm: new Array(512),
        g: new Array(512)
      };
    },
    mounted() {
      this.initCanvas();
      this.initNoise();
      window.addEventListener('resize', this.ge);
      this.ge();
      this.cs();
      this.loop();
    },
    methods: {
      ra(min, max) {
        return Math.random() * (max - min + 1) + min;
      },
      initCanvas() {
        this.canvas = this.$refs.canvas;
        this.ctx = this.canvas.getContext('2d');
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
        this.canvas.addEventListener('mousedown', this.bm);
      },
      clear() {
        this.ctx.globalCompositeOperation = 'destination-out';
        this.ctx.fillStyle = 'hsla(0,0%,0%,0.5)';
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.globalCompositeOperation = 'lighter';
      },
      ge() {
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
      },
      cs() {
        const x = this.ra(12, this.canvas.width - 12);
        const y = this.ra(12, 200);
        this.cr(x, y);
      },
      bm(e) {
        this.cr(e.clientX, e.clientY);
      },
      cr(x, y) {
        let ns = 350;
        while (ns--) {
          const dn = Math.random() * 6;
          const ve = this.ra(10, 20);
          const rad = 10 + Math.random() * 20;
          const eb = true;
          const particle = this.createParticle({ x, y, dn, ve, rad, eb });
          this.particles.push(particle);
        }
      },
      loop() {
        this.clear();
        if (this.ae && this.frame % 75 === 0) {
          this.cs();
        }
        this.particles.forEach((particle, index) => {
          const h = this.pz(this.tick, this.tick) * 360;
          const zs = `hsl(${h},80%, 50%)`;
          this.ctx.beginPath();
          this.ctx.fillStyle = zs;
          this.ctx.arc(particle.x, particle.y, particle.rad * 2, 0, 6.3);
          this.ctx.fill();
          this.ctx.closePath();
          particle.update();
          if (Math.abs(particle.rad) <= 2 && particle.eb) {
            particle.eb = false;
            let cn = 10;
            while (cn--) {
              this.particles.push(this.createParticle({
                x: particle.x,
                y: particle.y,
                rad: 24,
                dn: Math.random() * 6,
                ve: particle.ve * this.ra(2, 10),
                eb: false
              }));
            }
          }
          if (particle.rad <= 0.1 || particle.ve <= 0.1) {
            this.particles.splice(this.particles.indexOf(particle), 1);
          }
        });
        this.tick += 0.01;
        this.frame++;
        this.rd = requestAnimationFrame(this.loop);
      },
      createParticle(os) {
        const de = { x: 0, y: 0, rad: 10, dn: 0, ve: 0 };
        const particle = Object.assign({}, de, os);
        particle.vb = Math.cos(particle.dn) * particle.ve;
        particle.vp = Math.sin(particle.dn) * particle.ve;
        particle.an = 0.94;
        particle.dd = this.ra(90, 91) * 0.01;
        particle.gr = particle.rad * 0.01;
        particle.update = function () {
          this.x += this.vb;
          this.y += this.vp;
          this.vb *= this.an;
          this.vp *= this.an;
          this.ve *= this.an;
          this.rad *= this.dd;
          this.gr += 0.05;
        }
        return particle;
      },
      initNoise() {
        const p = [];
        for (let i = 0; i < 256; i++) {
          p[i] = i;
        }
        let j, tmp;
        for (let i = 0; i < 256; i++) {
          j = Math.floor(Math.random() * 256);
          tmp = p[i];
          p[i] = p[j];
          p[j] = tmp;
        }
        for (let i = 0; i < 256; i++) {
          this.pm[i] = this.pm[i + 256] = p[i];
        }
      },
      fa(t) {
        return t * (t * (t * 3 - 5) + 6);
      },
      rp(a, b, t) {
        return (1 - t) * a + t * b;
      },
      pz(x, y) {
        const bb = Math.floor(x);
        const mc = Math.floor(y);
        x = x - bb;
        y = y - mc;
        const u = this.fa(x);
        return this.rp(this.rp(2, 3, u), this.rp(2, 3, u), this.fa(y));
      }
    }
  };
  </script>
  
  <style lang="scss">
  .particles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    pointer-events: none;
  }
  canvas {
    //background: #000;
  }
  </style>
  