export default {
  bind(el) {
    let isProcessing = false;
    el.addEventListener("input", function (event) {
      if (isProcessing) return;
      isProcessing = true;

      const value = event.target.value;
      const newValue = value.replace(/[а-яёА-ЯЁ]/g, "");
      if (value !== newValue) {
        event.target.value = newValue;
        event.target.dispatchEvent(new Event("input"));
      }

      isProcessing = false;
    });
  },
};
