<template>
   <div>
       <div class="home-header">
            <div class="mobile-header mobile-header--variant:home">
                <div class="mobile-header__balance">
                    {{ user.balance }} {{ user.currency }}
                </div>
                <v-app-bar-nav-icon
                class="mobile-header__menu-icon"
                @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            </div>
            <jackpot-item />
            <mobile-search />
        </div>
        <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      dark
      right
    >
    <div class="menu__content">
      <div class="menu__list">
          <div class="menu__item" 
          @click="selectCategory(category)"
                :class="{ '_is-active' : isCurrentTab(category.code) }"
          v-for="category in categories" :key="category.id">
            {{ category.name }}
          </div>
          <!-- <div class="menu__list"></div> -->
          <!-- <div class="menu__item">
            {{ $t('favourites') }}
          </div> -->
          <hr class="menu__item menu__item--line" />
          <div class="menu__item" 
          >
          <span @click="toOpenInfoDialog">{{ $t('info') }}</span>
          </div>
          <!-- <div class="menu__item" 
          >
          <span @click="toOpenContactsDialog">{{ $t('contacts') }}</span>
          </div> -->
          <hr class="menu__item menu__item--line" />
          <div class="menu__item"
          @click="logout"
          >
            <span>{{ $t('exit') }}</span>
            <div class="menu__item--btn-sign-out button button_icon">
              <v-icon color="var(--white)">
                mdi-login-variant
              </v-icon>
            </div>
          </div>
      </div>
    </div>
    <v-dialog v-model="dialog" 
    fullscreen
    transition
    scrollable
        content-class="main-dialog"
        >
        <info-dialog 
        @close="toCloseDialog"
        v-if="isInfoDialog"
        >

        </info-dialog>
        <contacts-dialog 
        @close="toCloseDialog"
        v-if="isContactsDialog">

        </contacts-dialog>
        </v-dialog>
    </v-navigation-drawer>
   </div>
</template>

<script>
import InfoDialog from '@/components/Modal/InfoDialog';
import ContactsDialog from '@/components/Modal/ContactsDialog';
import MobileSearch from '@/components/Search/MobileSearch.vue';
import { mapGetters } from 'vuex'
import JackpotItem from './Jackpot/JackpotItem.vue';
    export default {
        name: "HomeHeader",
        components:{
          JackpotItem,
            MobileSearch,
            InfoDialog,
            ContactsDialog
        },
        data() {
            return {
              isInfoDialog: false,
                isContactsDialog: false,
                dialog: false,
                drawer: false,
                group: null,
                categories: [
                    {
                        id: 1,
                        name: this.$t('all'),
                        code: 'all'
                    },
                    {
                        id: 2,
                        name: this.$t('favourites'),
                        code: 'favorite'
                    },
                ],
            }
        },
        computed: {
            ...mapGetters({
                user: 'getUserInfo',
                 currentTab: 'gameModule/getCurrentTab'
            })
        },
        methods:{
          toCloseDialog(){
                this.isContactsDialog = false;
                this.isInfoDialog = false;
                this.dialog = false;
                
            },
            toOpenInfoDialog(){
                this.isInfoDialog = true;
                this.isContactsDialog = false;
                this.dialog = true;
            },
            toOpenContactsDialog(){
                this.isInfoDialog = false;
                this.isContactsDialog = true;
                this.dialog = true;
            },
          logout(){
                this.$store.dispatch('logOut');
                this.$router.push('/login');
            },
            isCurrentTab(code){
                return code === this.currentTab
            },
            selectCategory(category){
                this.$store.commit('gameModule/set',
                {type: 'currentTab', items: category.code}

                )
            },
            },
    }
</script>

<style lang="scss" scoped>
.home-header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 35px;
    z-index: 6;
    background-color: #000;
    background-image: radial-gradient(ellipse 50% 150% at left, rgba(0, 49, 173, .4) 0, transparent 100%), radial-gradient(ellipse 50% 150% at right, rgba(0, 49, 173, .4) 0, transparent 100%);
}
.mobile-header {
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
    right: 0;
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: background-color .5s;

    &__balance {
        margin: 0 12px;
        font-size: 24px;
        color: var(--white);
    }
    .v-app-bar__nav-icon {
      position: absolute;
      right: 0;
      padding-right: 4px;
      top: 0;
      height: 35px;
      .v-icon {
        height: 40px;
        font-size: 40px;
        width: 40px;
      }
    }
      
}
.mobile-header--variant:home {
}
.menu{
  &__content{
    flex: 1;
    width: 100%;
    height: 100%;
    max-width: 512px;
    background-color: rgba(0, 0, 0, .9);
    position: relative;
    padding-top: 10px;
    margin-left: auto;
    overflow-y: scroll;
  }
  &__item{
    font-size: 18px;
        height: 32px;
        width: 100%;
        color: hsla(0, 0%, 100%, .5);
        position: relative;
        padding: 0 32px;
        cursor: pointer;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        span{
          flex: 1 1 100%;
          text-align: left;
        }
        &._is-active{
          color: var(--white);
        }
  }
  &__list{
    display: flex;
    flex-direction: column;
    align-items: center;
    .menu__list{
      padding: 0 0 8px 30px;
    }
  }
  &__item--line{
    border-top: 1px solid #fff;
    height: 2px;
    width: 86%;
    margin: 0.5em 0 ;
  }
  &__item--btn-sign-out{
    
  }
}
</style>