<template>
    <v-card>
        <v-card-title class="title-dialog">
            <span>{{ $t('contacts') }}</span>
        </v-card-title>
        <v-card-text>
            <div class="content-dialog">
            <div class="content-dialog__wrapper">
                <!-- <div class="content-dialog__bonus-info">
                    <div class="content-dialog__title">{{ $t('balance') }}:</div>
                    <div class="content-dialog__value">{{ user.balance }} {{ user.currency }}</div>
                </div> -->
                </div>
                </div>
                </v-card-text>
                <v-card-actions>
                    <button  @click="$emit('close')" class="button dialog-button">
                        {{ $t('lobby') }}
                    </button>
                </v-card-actions>
    </v-card>
</template>

<script>

    import { mapGetters } from "vuex";
    export default {
        name: "InfoDialog",
        computed:{
            ...mapGetters({
                user: 'getUserInfo',
            })

        },
    }
</script>

<style lang="scss" scoped>

</style>