import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router/router";
import * as querystring from "querystring";
//========================================================================================================================================================
// import modules

import gameModule from "./modules/gameModule";
import favoriteGames from "./modules/favoriteGames";
import localization from "./modules/localization";
import styles from "./modules/styles";
import currencies from "./modules/currencies";
Vue.use(Vuex);
// axios.defaults.xsrfCookieName = "Authorization";
// axios.defaults.xsrfHeaderName = "Auth-token";
const currentDomain = window.location.hostname;

let ws_1;
let ws_2;

if (currentDomain.includes("vip-casino.pro")) {
  ws_1 = "wss://main.vip-casino.pro/users/ws";
  ws_2 = "wss://main.vip-casino.pro/main-api/maintenance/ws";
} else {
  ws_1 = "wss://main.takemybet.pro/users/ws";
  ws_2 = "wss://main.takemybet.pro/main-api/maintenance/ws";
}

export const WS_URL = ws_1;
export const WS_URL2 = ws_2;
// export const WS_URL2 = "wss://main.vip-casino.pro/main-api/maintenance/ws";
// export const WS_URL = "wss://main.vip-casino.pro/users/ws";
let socket;
// const socket = new WebSocket(WS_URL);
const socket2 = new WebSocket(WS_URL2);
export default new Vuex.Store({
  state: {
    openMenu: false,
    jackpotOnGoing: {
      status: null,
    },
    jackpotIncrease: {
      jackpot_amount: 0,
      progress: 0,
      agent_id: 0,
    },
    jackpotWin: {
      user_id: 0,
      username: null,
      jackpot_amount: 0,
    },
    jackpot: {
      id: null,
      currency: null,
      win_amount: null,
    },
    userInfo: {
      avatar: null,
      avatar_id: null,
      time_limit: null,
      id: null,
      username: null,
    },
    socket: {
      isConnected: false,
      message: "",
      reconnectError: false,
    },
    socket2: false,
  },
  mutations: {
    JACKPOT_NULL(state) {
      state.jackpot = {
        id: null,
        currency: null,
        win_amount: null,
      };
    },
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
      const token = localStorage.getItem("jwt");
      // console.log("token", token);
      if (token) {
        event.currentTarget.send(JSON.stringify({ token: token }));
      }
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event);
    },
    SOCKET_ONMESSAGE(state, message) {
      state.socket.message = message;
      try {
        // console.log("message", message);
        const parsedMessage = message.data;
        //console.log(parsedMessage);
        // console.log("parsedMessage", parsedMessage);
        // console.log("message.type", message.type);
        switch (message.type) {
          case "jackpot_increase": {
            if (parsedMessage) {
              state.jackpotIncrease = parsedMessage;
              // console.log("jackpot", state.jackpot);
            }
            break;
          }
          case "jackpot_win": {
            if (parsedMessage) {
              state.jackpotWin = parsedMessage;
              // console.log("jackpot", state.jackpot);
            }
            break;
          }
          case "jackpot_ongoing": {
            if (parsedMessage) {
              state.jackpotOnGoing = parsedMessage;
              // console.log("jackpot", state.jackpot);
            }
            break;
          }
          default:
          // console.error("Unknown message type:", parsedMessage.type);
        }
      } catch (error) {
        console.error("Error parsing message:", error);
      }
    },
    SOCKET_RECONNECT(state, count) {
      console.info(state, count);
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
    set(state, { type, items }) {
      state[type] = items;
    },
    clearUserInfo(state) {
      state.jackpot = {
        id: null,
        currency: null,
        win_amount: null,
      };
      state.userInfo = {
        avatar: null,
        avatar_id: null,
        time_limit: null,
        id: null,
        username: null,
      };
      localStorage.removeItem("jwt");
    },
  },
  getters: {
    getUserInfo: (state) => state.userInfo,
    getJackpot: (state) => state.jackpot,
    getJackpotWin: (state) => state.jackpotWin,
    getJackpotOnGoing: (state) => state.jackpotOnGoing,
    getJackpotIncrease: (state) => state.jackpotIncrease,
  },
  actions: {
    initializeWebSocket({ commit }) {
      socket = new WebSocket(WS_URL);

      // console.log("Initializing WebSocket:", socket);
      // console.log("111");
      socket.addEventListener("open", (event) => {
        // console.log("222");
        // console.error("WebSocket open:", event);
        commit("SOCKET_ONOPEN", event);
      });

      socket.addEventListener("message", (event) => {
        // console.error("WebSocket message:", event);
        const message = JSON.parse(event.data);
        commit("SOCKET_ONMESSAGE", message);
      });

      socket.addEventListener("close", (event) => {
        // console.error("WebSocket close:", event);
        commit("SOCKET_ONCLOSE", event);
      });
      socket.addEventListener("error", (event) => {
        console.error("WebSocket error:", event);
      });

      socket2.addEventListener("open", (event) => {
        // console.error("WebSocket open:", event);
        commit("SOCKET_ONOPEN", event);
      });

      socket2.addEventListener("message", (event) => {
        const message = JSON.parse(event.data);
        // console.error("WebSocket message:", event);
        commit("set", {
          type: "socket2",
          items: message.status,
        });
      });

      socket2.addEventListener("close", (event) => {
        // console.error("WebSocket close:", event);
        commit("SOCKET_ONCLOSE", event);
      });
      socket2.addEventListener("error", (event) => {
        console.error("WebSocket error:", event);
      });
    },
    //========================================================================================================================================================

    // awaitGetGames
    async awaitGetGames({ commit, state }, data) {
      let queryString = `/api/games/filter?size=35`;

      const params = [];
      if (data.provider && data.provider !== "All Games") {
        params.push(`provider=${encodeURIComponent(data.provider)}`);
      }
      if (data.providerNext && data.providerNext !== "All Games") {
        params.push(`provider=${encodeURIComponent(data.providerNext)}`);
      }
      if (data.providerPrevious && data.providerPrevious !== "All Games") {
        params.push(`provider=${encodeURIComponent(data.providerPrevious)}`);
      }
      if (data.game) {
        params.push(`name=${encodeURIComponent(data.game)}`);
      }
      if (params.length) {
        queryString += `&${params.join("&")}`;
      }
      await axios
        .get(queryString)
        .then((response) => {
          if (response.status === 200) {
            const games = response.data.items
              .filter(({ Game }) => Game.active === true)
              .map(({ Game }) => Game);
            commit("set", { type: "games", items: games });
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
    },
    async logOut({ commit }) {
      commit("clearUserInfo");
      window.location.reload();
    },
    async singIn({ state, commit, dispatch }, data) {
      let res = false;
      await axios
        .post(
          `/api/auth/token`,
          querystring.stringify({
            username: data.username,
            password: data.password,
            fingerprint: JSON.stringify(data.fingerprint),
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response) {
            res = true;
            localStorage.clear();
            localStorage.setItem("jwt", response.data.access_token);
          }
        })
        .catch((err) => {
          // if (axiosError.response && axiosError.response.status === 403) {
          //   const blockDetails = axiosError.response.data.detail;
          //   const blockType = blockDetails.split("-")[0].trim();
          //   let blockEnd = blockDetails.split("-").slice(1).join("-").trim();

          //   switch (blockType) {
          //     case "BLOCK PERMANENT":
          //     case "BLOCK TEMPORARY":
          //       errorType = "ban";
          //       date = new Date(blockEnd);
          //       break;
          //     default:
          //   }
          // }
          throw err;
        });
      return res; // Pass the errorType variable instead
    },
    async awaitGetUserInfo({ commit, state }) {
      await axios
        .get(`/api/users/me`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200 && "id" in response.data) {
            let user = response.data;
            const balanceInDollars = user.balance;
            user.balance = balanceInDollars.toFixed(2);
            commit("set", { type: "userInfo", items: user });
            if (user.birthday != null && !isNaN(Date.parse(user.birthday))) {
              let date = new Date(user.birthday);
              user.birthday = date
                .toISOString()
                .slice(0, 10)
                .replace(/-/g, "-");
            }
          } else if (response.data.description === "invalid token") {
            commit("clearUserInfo");
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
      //await dispatch('getUserBonuses');
    },
    async getGameUrl({ commit, dispatch }, data) {
      let url = false;
      let err = null;
      await axios
        .get(`/api/games/session_url/${data.id}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            language: data.locale, // use the current locale as the language parameter
            demo_flag: data.demo_flag,
            client_type: data.client_type,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            url = response.data;
          }
        })
        .catch((error) => {
          const detail = error.response.data.detail;
          if (detail === "Bonus balance cannot be present") {
            err = "BonusLiveError";
          } else if (detail === "Not enough money") {
            err = "isNoMoney";
          }
        });

      return [url, err];
    },
    async getRandomGameUrl({ commit, dispatch }, data) {
      let url = false;
      await axios
        .get(`/api/games/session_url/random/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            language: data.locale, // use the current locale as the language parameter
            client_type: data.client_type,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            url = response.data;
          }
        })
        .catch((error) => {});
      return url;
    },
    //========================================================================================================================================================
    async awaitGetJackpot({ commit }) {
      await axios
        .get(`/api/jackpots/my`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            // const jackpot = response.data.filter(jack => jack.is_active);
            commit("set", { type: "jackpot", items: response.data });
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
    },
  },
  modules: {
    gameModule,
    localization,
    styles,
    currencies,
    favoriteGames,
  },
});
//========================================================================================================================================================
// How to get notifications
// 1. add to 'dispatch' in actions
// 2. add new notification error, info, success
//const notification = {
//  type: "success",
//  message: "message" this.$t('justError'),
//};
//dispatch("notifications/add", notification);
